import React, { useEffect, useState } from 'react';
import PopSideHeader from './PopSideHeader';
import PopSideTabs from './PopSideTabs';
// import { PopSideData_MOCK } from 'common/mocks/actionItemsPopSide_mock';
import PopSideDetailsTab from './tabs/PopSideDetailsTab';
import PopSideCVEsTab from './tabs/PopSideCVEsTab';
import PopSideFooter from './PopSideFooter';

import './ActionItemsPopside.scss';
import PopSideCommentsTab from './tabs/PopSideCommentsTab';
import { AppLoading } from 'common/components/appLoading/appLoading';
import PopSideGraphTab from './tabs/PopSideGraphTab';
import { RestApi } from 'common/services/rest-api.service';
import { setCommentsTabCount } from 'features/popSideSlice';
import { useDispatch } from 'react-redux';

// import { RestApi } from 'common/services/rest-api.service';
// import { useDispatch } from 'react-redux';
// import { showCustomSuccess } from 'features/AlertSlice';

const ActionItemsPopside = ({
  data,
  onHide,
  showActions,
  activeTab = 'details',
  rowsIds
}: {
  data: any;
  showActions: boolean;
  onHide: (bln: boolean) => void;
  activeTab?: string;
  rowsIds?: any;
}) => {
  const [rowData, setRowData] = useState(data);
  const [tabActive, setTabActive] = useState(activeTab);
  const [requiredHeight, setRequiredHeight] = useState<any>(0);
  const [isLoading, setIsLoading] = useState(true);
  const [currentIndex, setCurrentIndex] = useState<number | null>(
    rowsIds?.findIndex((id: string) => id === rowData.id) || null
  );
  const [isLoadingInner, setIsLoadingInner] = useState(false);

  const dispatch = useDispatch<any>();

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
      const row: HTMLElement | null = document.querySelector(`[data-id~='${rowData.id}']`);
      if (!!row) {
        row.classList.add('action-item-popside-row-selected');
      }
    }, 1000);

    const activeTab = !data.last_closed_at ? 'open' : 'closed';
    let url = `remediation/action-items/${activeTab}/${data.id}/comments/`;
    RestApi.getData(url).subscribe(
      (response: any) => {
        dispatch(setCommentsTabCount(response.count));
      },
      error => {}
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!!rowsIds && !!rowsIds?.length) {
      setCurrentIndex(rowsIds?.findIndex((id: string) => id === rowData.id));
    }
  }, [rowsIds, rowData.id]);

  const height = requiredHeight < 40 ? 0 : requiredHeight - 40;

  const fetchData = (id: string) => {
    const activeTab = !data.last_closed_at ? 'open' : 'closed';
    let url = `remediation/action-items/${activeTab}/${id}/`;
    RestApi.getData(url).subscribe(
      (response: any) => {
        dispatch(setCommentsTabCount(response.user_comments_count));
        setRowData(response);
        setIsLoadingInner(false);

        const row: HTMLElement | null = document.querySelector(`[data-id~='${id}']`);
        if (!!row) {
          row.classList.add('action-item-popside-row-selected');
        }
      },
      error => {}
    );
  };

  const handleOnNavigate = async (isUp: boolean) => {
    const selectedRow = document.querySelector('.action-item-popside-row-selected');
    if (!!selectedRow) {
      selectedRow.classList.remove('action-item-popside-row-selected');
    }
    setIsLoadingInner(true);
    setTabActive('details');

    const currInx = rowsIds.findIndex((id: string) => id === rowData.id);
    if (isUp) {
      if (currInx === 0) {
        return;
      }
      await fetchData(rowsIds[currInx - 1]);
      setCurrentIndex(currInx - 1);
    } else {
      if (currInx === rowsIds.length - 1) {
        return;
      }
      await fetchData(rowsIds[currInx + 1]);
      setCurrentIndex(currInx + 1);
    }
  };

  const handleOnHide = (bln: boolean) => {
    const selectedRow = document.querySelector('.action-item-popside-row-selected');
    if (!!selectedRow) {
      selectedRow.classList.remove('action-item-popside-row-selected');
    }
    onHide(bln);
  };

  return isLoading ? (
    <AppLoading />
  ) : (
    <div className="ActionItemsPopside">
      <PopSideHeader
        data={rowData}
        showActions={showActions}
        onNavigate={handleOnNavigate}
        currentIndex={currentIndex}
        isLastIndex={currentIndex === rowsIds?.length - 1}
      />
      {!isLoadingInner ? (
        <>
          <PopSideTabs data={rowData} tabActive={tabActive} setTabActive={setTabActive} />
          <div className="scrollbar-common">
            <div
              className="popside-content"
              style={{
                height: `calc(100vh - ${height}px - 345px)`,
                overflowY: tabActive !== 'comments' ? 'scroll' : 'hidden'
              }}
            >
              {tabActive === 'details' && <PopSideDetailsTab data={rowData} />}
              {tabActive === 'cves' && <PopSideCVEsTab data={rowData} />}
              {tabActive === 'comments' && <PopSideCommentsTab data={rowData} footerHeight={height} />}
              {tabActive === 'graph' && <PopSideGraphTab asset={rowData.asset} id={rowData.id} type={rowData.type} />}
            </div>
          </div>
          <PopSideFooter data={rowData} onHide={handleOnHide} setRequiredHeight={setRequiredHeight} />
        </>
      ) : (
        <AppLoading />
      )}
    </div>
  );
};

export default ActionItemsPopside;
