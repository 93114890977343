import './CNAPPAlertRawTab.scss';
import { Common } from 'common/services/common.service';
import ReactJson from 'react-json-view';

const CNAPPAlertRawTab = (props: any) => {
  return (
    <div className="CNAPPAlertRawTab">
      <ReactJson
        theme={`grayscale:inverted`}
        iconStyle="square"
        indentWidth={1}
        name={false}
        displayDataTypes={false}
        displayObjectSize={false}
        src={props.data.details}
        style={{ borderRadius: 10, background: '#f9faff', padding: 10, fontSize: 12, border: '1px solid #ececec' }}
        enableClipboard={(copy: any) => Common.copyText(copy.src)}
      />
      {/* <textarea>{JSON.stringify(props.data.details)}</textarea> */}
    </div>
  );
};

export default CNAPPAlertRawTab;
