import { useState } from 'react';
import PopSideCVEs from 'pages/action-items/popside/tabs/PopSideCVEsTab';
import { ITechnology } from 'pages/inventory/singleAsset/interface';
import { Text } from '@cyberpion/cyberpion-ui';
import { IconArrow } from 'common/components/Icons/IconArrow/IconArrow';
import { makeStyles } from '@material-ui/core/styles';
import { Popover } from '@mui/material';
import { TechnicalDetails } from 'common/components/TechnicalDetails/TechnicalDetails';
import './CvesPopSide.scss';

const iconTech = (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M15.5 5.5L8 0.5L0.5 5.5V10.5L8 15.5L15.5 10.5V5.5ZM8.625 2.375C8.625 2.02988 8.34513 1.75 8 1.75C7.65475 1.75 7.375 2.02988 7.375 2.375V5.1655L4.25 7.24887L2.09662 5.81337C1.8095 5.62187 1.42138 5.6995 1.23 5.98662C1.0385 6.27387 1.11612 6.66187 1.40325 6.85337L3.89113 8.51188C3.899 8.5175 3.907 8.52275 3.91512 8.52788L7.375 10.8345V13.625C7.375 13.9701 7.65475 14.25 8 14.25C8.34513 14.25 8.625 13.9701 8.625 13.625V10.8345L12.0849 8.52788C12.093 8.52275 12.101 8.5175 12.1089 8.51188L14.5966 6.85337C14.8839 6.66187 14.9615 6.27387 14.77 5.98662C14.5785 5.6995 14.1905 5.62187 13.9032 5.81337L11.75 7.24887L8.625 5.1655V2.375ZM10.6233 8L8 6.25113L5.37675 8L8 9.74888L10.6233 8Z"
      fill="#3455DD"
    />
  </svg>
);

const iconEOL = (
  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
    <path
      d="M1.8335 4.66667C1.8335 2.36548 3.69898 0.5 6.00016 0.5C8.30135 0.5 10.1668 2.36548 10.1668 4.66667V11.5H1.8335V4.66667Z"
      stroke="#F7981F"
    />
    <line y1="11.5" x2="12" y2="11.5" stroke="#F7981F" />
  </svg>
);

const useStyles = makeStyles({
  popoverRoot: {
    marginTop: '10px',
    overflow: 'hidden',
    border: '1px solid #ECECEC',
    backgroundColor: '#f9faff',
    boxShadow:
      '0px 77.291px 61.833px 0px rgba(108, 73, 172, 0.07), 0px 32.29px 25.832px 0px rgba(108, 73, 172, 0.05), 0px 17.264px 13.811px 0px rgba(108, 73, 172, 0.04), 0px 9.678px 7.742px 0px rgba(108, 73, 172, 0.04), 0px 5.14px 4.112px 0px rgba(108, 73, 172, 0.03), 0px 2.139px 1.711px 0px rgba(108, 73, 172, 0.02)',

    '& .cves-popside-technical-details': {
      margin: '10px !important',
      '& .react-json-view': { fontSize: '11px !important', backgroundColor: '#f9faff !important' }
    },
    '& .technical-details-header-scope': {
      marginBottom: '0px !important'
    }
  }
});

const CvesPopSide = ({ data, onHide }: { data: any; onHide: (bln: boolean) => void }) => {
  const [technicalDetails, setTechnicalDetails] = useState<any>(null);
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);

  const classes = useStyles();

  const getShortenAppName = (appName: string): string => {
    if (!appName) {
      return '';
    }
    const appNameAsArray = appName.split(' ');

    if (appName.length <= 3) {
      return appName;
    }

    if (appNameAsArray.length > 1) {
      return `${appNameAsArray[0].charAt(0)}${appNameAsArray[1].charAt(0)}`;
    }

    return appName.substring(0, 2);
  };

  const getAppImage = (item: ITechnology): JSX.Element => {
    if (item.logo_url) {
      return <div className="app-logo-image" style={{ backgroundImage: `url('${item.logo_url}')` }} />;
    }

    return <div className="app-custom-logo">{getShortenAppName(item.label)}</div>;
  };

  let eol;
  if (data.end_of_life) {
    const y = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(new Date(data.end_of_life));
    const m = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(new Date(data.end_of_life));
    const d = new Intl.DateTimeFormat('en', { day: 'numeric' }).format(new Date(data.end_of_life));
    eol = `${d}/${m}/${y}`;
  }

  const handleTechnicalDetailsOnClick = (item: any) => (event: React.MouseEvent<HTMLElement>) => {
    setTechnicalDetails(item.detection_details);
    setAnchorEl(event.target as HTMLDivElement);
  };

  const handleTechnicalDetailsClose = () => {
    setAnchorEl(null);
  };

  const showTechnicalDetails = Boolean(anchorEl);

  return (
    <div className="technologies-CVEs-Popside">
      <div className="header-row">
        {iconTech}
        <Text weight={500} textSize={14} style={{ marginLeft: 12 }}>
          Technologies
        </Text>
        <div className="icon-arrow">
          <IconArrow />
        </div>
        <Text textSize={13} weight={500}>{`CVEs (${data.cves.length})`}</Text>
      </div>
      <div style={{ backgroundColor: '#FFF' }}>
        <div className="tech-row">
          <div className="tech-image">{getAppImage(data)}</div>
          <div className="tech-row-details">
            {data.end_of_life && (
              <div className="eol-badge">
                <div className="icon">{iconEOL}</div>
                <Text textSize={14} textColor="#F7981F" style={{ paddingLeft: 6 }}>
                  {`EOL ${eol}`}
                </Text>
              </div>
            )}
            <Text textSize={16} weight={500} style={{ marginLeft: 12 }}>
              {`${data.label}${!!data.version ? ' V' + data.version : ''}`}
            </Text>
            <div style={{ display: 'flex', alignItems: 'center', marginTop: 6 }}>
              <Text textSize={14} textColor="#8C909A" style={{ marginLeft: 12 }}>
                {!!data.detection_method ? 'Detection Method: ' + data.detection_method : 'N/A'}
              </Text>
              {data.detection_details && (
                <div className="technical-details" onClick={handleTechnicalDetailsOnClick(data)}>
                  <Text textSize={14} textColor="#3659DE" style={{ marginLeft: 8 }}>
                    Details
                  </Text>
                </div>
              )}
            </div>
          </div>
        </div>
        <div style={{ backgroundColor: '#EAEAEA', height: 12 }} />
      </div>
      <Popover
        open={showTechnicalDetails}
        anchorEl={anchorEl}
        onClose={handleTechnicalDetailsClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        classes={{ paper: classes.popoverRoot }}
        disableScrollLock={true}
      >
        <div className="cves-popside-technical-details">
          <TechnicalDetails data={technicalDetails} isActionItemsPDF={true} />
        </div>
      </Popover>
      <PopSideCVEs data={{ cves: data.cves }} />
    </div>
  );
};

export default CvesPopSide;
