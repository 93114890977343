import { Button } from '@cyberpion/cyberpion-ui';
import { useDispatch, useSelector } from 'react-redux';
import { IReduxState } from 'app/store';
import { saveSeedData, setSeedSaveEnabled } from 'features/MSSPSlice';
import { setDelay, showError, showSuccess } from 'features/AlertSlice';
import { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { RunScanButton } from './RunScanButton/RunScanButton';
import './ActionsRow.scss';

export function ActionsRow(props: any) {
  const [loading, setLoading] = useState<boolean>(false);
  const { seedData, seedSaveEnabled, scrollPosition } = useSelector((state: IReduxState) => state.mssp);
  const dispatch = useDispatch<any>();
  const initialSave: any = useRef();
  const onSubmit = async () => {
    setLoading(true);
    const res = await dispatch(saveSeedData(seedData));
    dispatch(setSeedSaveEnabled(false));
    setLoading(false);
    if (res && typeof res.payload === 'string') {
      dispatch(setDelay(10000));
      dispatch(showError(res.payload));
    } else {
      dispatch(showSuccess('Your changes saved Succesfully'));
    }
  };

  useEffect(() => {
    if (!initialSave.current && Array.isArray(seedData) && seedData.length) {
      initialSave.current = true;
      dispatch(saveSeedData(seedData));
    }
    const intervalMinutes = 10;
    const interval = setInterval(() => {
      if (Array.isArray(seedData) && seedData.length) {
        dispatch(saveSeedData(seedData));
      }
    }, intervalMinutes * 60 * 1000);

    return () => {
      clearInterval(interval);
    };
  }, [seedData, dispatch]);

  return (
    <footer className={classNames('ActionsRow', { scroll: !!scrollPosition })}>
      <Button
        text="Save"
        onClick={onSubmit}
        buttonStyle="main"
        size="medium"
        type="button"
        disabled={!seedSaveEnabled || loading}
      />
      <RunScanButton disabled={loading} />
    </footer>
  );
}
