import { BrowserRouter as Router, Redirect } from 'react-router-dom';
import { Route } from 'react-router-dom';
import { AppRouting } from 'routes/AppRouting';
import { LicenseInfo } from '@mui/x-data-grid-pro';
import { useEffect, useRef, useState } from 'react';
import { Login } from 'login/Login';
import { useSelector, useDispatch } from 'react-redux';
import { AppLoading } from 'common/components/appLoading/appLoading';
import { urlToStateConverter } from 'common/services/UrlToStateConverter.service';
import { checkSession, setAccount } from 'features/LoginSlice';
import { IReduxState } from 'app/store';
import { setGlobalFilter } from 'features/GeneralSlice';
import GenerateExecutiveReport from 'common/components/GenerateExecutiveReport/GenerateExecutiveReport';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import GenerateActionItemsPDF from 'common/components/GenerateActionItemsPDF/GenerateActionItemsPDF';
import GenerateThreatsReport from 'common/components/GenerateThreatsReport/GenerateThreatsReport';
import { ASMWrapper } from 'common/components/ASMWrapper/ASMWrapper';
import { zendesk } from 'common/services/zendesk.service';
import { Alert } from './common/components/Alert/Alert';
import { Downloads } from './common/components/Downloads/Downloads';
import { LoginService } from 'login/services/login.service';
import { env } from 'env';
// import { useFlags } from 'launchdarkly-react-client-sdk';
// import Downtime from 'common/components/DownTime/Downtime';

import 'styles/styles.scss';
import 'App.scss';
import { Common } from 'common/services/common.service';
import { fetchDefaultViews, fetchGenericViews, fetchUserCreatedViews } from 'features/ViewsSlice';
import { PromptPopup } from 'common/components/PromptPopup/PromptPopup';

declare const window: any;

// MAYBE FOR FUTURE USE
// import  {Translate}  from "common/translate/translate.service";
// MAYBE FOR FUTURE USE
// Translate.addDefaultLocale(require('./locales/en.json'));

export default function App() {
  const isInitialMount = useRef(true);
  const [_loading, setIsLoading] = useState<boolean>(true);
  const { loading, isLoggedIn, user } = useSelector((state: IReduxState) => state.login);
  const [showASM, setShowASM] = useState(false);
  const dispatch = useDispatch<any>();
  const ldClient = useLDClient();
  // const flags = useFlags();

  // useEffect(() => {
  //   console.log(location.pathname);
  // }, [location.pathname]);

  // useEffect(() => {
  //   if (ldClient) {
  //     if (user.mssp_company_name && user.mssp_company_hash) {
  //       ldClient.identify({ key: user.mssp_company_name }, user.mssp_company_hash);
  //     } else if (user.company_name && user.company_hash) {
  //       ldClient.identify({ key: user.company_name }, user.company_hash);
  //     }
  //   }
  // }, [user, ldClient]);

  useEffect(() => {
    if (ldClient && user.company_name && user.company_hash) {
      ldClient.identify({ key: user.company_name }, user.company_hash);
    }
  }, [user, ldClient]);

  useEffect(() => {
    if (window.gtag) {
      window.gtag('config', env.REACT_APP_GOOGLE_ANALYTICS_ID);
    }
  }, []);

  useEffect(() => {
    if (!!user) {
      const show = LoginService.isCyberpionUser(user);
      setShowASM(!!show);
    }
  }, [user]);

  useEffect(() => {
    if (!loading) {
      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    }
  }, [loading]);

  useEffect(() => {
    if (isLoggedIn) {
      zendesk.inject();
    }
  }, [isLoggedIn]);

  useEffect(() => {
    if (isInitialMount.current) {
      populateInitialState();
      isInitialMount.current = false;
      LicenseInfo.setLicenseKey(
        'bd9d3b8bae4a34ed7d811328293c6b84T1JERVI6MzczODIsRVhQSVJZPTE2NzU4NDk2NTAwMDAsS0VZVkVSU0lPTj0x'
      );
    }
  });

  useEffect(() => {
    dispatch(checkSession());
  }, [dispatch]);

  const ASMWrapperExpaneded = () => {
    return showASM ? <ASMWrapper isExpand={true} /> : null;
  };

  const populateInitialState = () => {
    const group = urlToStateConverter.getGroup();
    const account = urlToStateConverter.getAccount();
    dispatch(setGlobalFilter(group));
    dispatch(setAccount(account));
    dispatch(fetchDefaultViews());
    dispatch(fetchGenericViews());
    dispatch(fetchUserCreatedViews());
  };

  return (
    <div className="flex-container">
      <Alert />
      <PromptPopup />
      <Downloads />
      {/* {!!Object.keys(flags).length && flags.isShowDowntimeScreen && <Downtime />} */}
      {_loading && <AppLoading fullscreen size="big" />}
      {!_loading && (
        <Router>
          <Route exact path="/">
            {user.is_mssp_user && !Common.getParamFromUrl('account') ? (
              <Redirect to="/pages/accounts" />
            ) : (
              <Redirect to="/pages/dashboard" />
            )}
          </Route>
          <Route path="/login" component={Login} />
          <Route path="/new-user" component={Login} />
          <Route path="/forgot-password" component={Login} />
          <Route path="/pages/:page" component={AppRouting} />
          <Route path="/settings/generate-executive-report" component={GenerateExecutiveReport} />
          <Route path="/services/asm" component={ASMWrapperExpaneded} />
          <Route path="/action-items-pdf" component={GenerateActionItemsPDF} />
          <Route path="/generate-threats-report" component={GenerateThreatsReport} />
        </Router>
      )}
    </div>
  );
}
