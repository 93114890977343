import { Button } from '@cyberpion/cyberpion-ui';
import { useFlags } from 'launchdarkly-react-client-sdk';

export function MSSPLoginButton(props: any) {
  const flags = useFlags();

  return (
    <a
      href={`/pages/${
        props.params?.row?.scan_status === 'Seed Data Ready' && flags.isMsspAddAccount
          ? 'settings/seed-data'
          : 'dashboard'
      }/?account=${props.params.row.name}`}
      target="_blank"
      rel="noreferrer"
    >
      <Button text="Login" type="button" buttonStyle="secondary" size="small" onClick={() => {}} />
    </a>
  );
}
