import { AppLoading } from 'common/components/appLoading/appLoading';
import { RestApi } from 'common/services/rest-api.service';
import { useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { showSuccess } from 'features/AlertSlice';
import { Button, Text } from '@cyberpion/cyberpion-ui';
import Checkbox from '@mui/material/Checkbox';
import { makeStyles } from '@material-ui/core/styles';
import { Common } from 'common/services/common.service';
import { IReduxState } from 'app/store';
import { setDropDownWithAction, setShowAddCommentPopup, updateRows } from 'features/GridSlice';

import './AddCommentPopup.scss';
import { setCommentsTabCount } from 'features/popSideSlice';

const useStyles = makeStyles({
  checkboxRoot: {
    padding: '0px !important',
    width: 'auto !important',
    '& .MuiSvgIcon-root': {
      width: '18px !important',
      height: '18px !important'
    }
  }
});

export function AddCommentPopup(props: any) {
  const [loading, setLoading] = useState<boolean>(false);
  const [isConfirmDisabled, setIsConfirmDisabled] = useState(true);
  const refTextArea = useRef<HTMLTextAreaElement>(null);
  const refCheckbox = useRef<HTMLInputElement>(null);
  const { dropDownWithAction, rows } = useSelector((state: IReduxState) => state.grid);
  const { commentsTabCount } = useSelector((state: IReduxState) => state.popSide);
  const { user } = useSelector((state: IReduxState) => state.login);
  const dispatch = useDispatch<any>();
  const classes = useStyles();

  const onApply = () => {
    setLoading(true);
    const url = Common.AddMsspAccountIfNeeded('remediation/action-items/state/');
    const payload = {
      ids: Object.keys(dropDownWithAction.listLastStatusOption),
      state: dropDownWithAction.nextStatusOption?.name,
      comment: refTextArea.current?.value
    };
    RestApi.setData(url, payload, 'POST').subscribe((response: any) => {
      const updatedRows = Object.keys(dropDownWithAction.listLastStatusOption)?.reduce((all: any, id: any) => {
        const row = rows.find((row: any) => row.id === id);
        return [
          ...all,
          {
            ...row,
            user_comments_count: row.user_comments_count + 1,
            state_update_user: user.email,
            state_update_timestamp: new Date().toISOString()
          }
        ];
      }, []);
      dispatch(updateRows({ rows: updatedRows, identifier: 'id' }));
      dispatch(setCommentsTabCount(commentsTabCount + 2));
      dispatch(setShowAddCommentPopup(false));

      dispatch(
        setDropDownWithAction({
          listLastStatusOption: null,
          nextStatusOption: null
        })
      );
      setLoading(false);
      dispatch(showSuccess('Status Updated Successfully'));
    });
  };

  const handleCancelOnClick = () => {
    const updatedRows = Object.keys(dropDownWithAction.listLastStatusOption)?.reduce((all: any, id: any) => {
      const row = rows.find((row: any) => row.id === id);
      return [
        ...all,
        {
          ...row,
          current_state: dropDownWithAction.listLastStatusOption[id].label
        }
      ];
    }, []);
    dispatch(updateRows({ rows: updatedRows, identifier: 'id' }));
    props.setVisible(false);
  };

  const handleIsConfirmDisabled = () => {
    setIsConfirmDisabled(!refCheckbox.current?.checked || !refTextArea.current?.value);
  };

  return (
    <div className="AddCommentPopup popup-inner-wrapper">
      {loading && <AppLoading />}
      <div className="popup-inner">
        <Text textSize={18} weight={500} style={{ marginBottom: 12 }}>
          Add evidence and confirmation
        </Text>
        <Text textSize={13} textColor="#8C909B" style={{ lineHeight: 1.35, marginBottom: 16 }}>
          Please provide the specific patch name and any relevant details that confirm that a security patch was in fact
          applied to remediate the risk. This information will be included as a comment associated with this Action
          Item.
        </Text>

        <div className="text-area">
          <textarea
            ref={refTextArea}
            className="add-evidence-textarea"
            placeholder="Add Evidence"
            onChange={handleIsConfirmDisabled}
          />
        </div>
        <div style={{ flexGrow: 1, display: 'flex', alignItems: 'center', marginBottom: 10 }}>
          <Checkbox
            inputRef={refCheckbox}
            onChange={handleIsConfirmDisabled}
            disableRipple
            classes={{ root: classes.checkboxRoot }}
            sx={{
              marginRight: 0.75
            }}
          />
          <Text textSize={13} family="Rubik" textColor="#8C909B">
            I confirm a security patch was applied
          </Text>
        </div>
        <div className="buttons-wrapper">
          <Button
            buttonStyle="secondary"
            disabled={false}
            type="button"
            size="small"
            onClick={handleCancelOnClick}
            text="Cancel"
          />
          <Button
            buttonStyle="main"
            disabled={isConfirmDisabled}
            type="button"
            size="small"
            onClick={onApply}
            text="Confirm"
          />
        </div>
      </div>
    </div>
  );
}
