import './EditAccount.scss';
import { Button } from '@cyberpion/cyberpion-ui';
import { ViewMSSP } from './ViewMSSP/ViewMSSP';
import { EditMSSP } from './EditMSSP/EditMSSP';
import { useEffect, useState } from 'react';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { editCompany, getAccountInfo, getCompanyConf } from 'features/MSSPSlice';
import { IReduxState } from 'app/store';
import { showError, showSuccess } from 'features/AlertSlice';
import { getRows } from 'features/GridSlice';
import { useFlags } from 'launchdarkly-react-client-sdk';

export function EditAccount(props: any) {
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [updateObj, setUpdateObj] = useState<{ plan?: string; scan_frequency?: string }>({});
  const { accountInfo } = useSelector((state: IReduxState) => state.mssp);
  const [loading, setLoading] = useState<boolean>(false);
  const dispatch = useDispatch<any>();
  const flags = useFlags();

  useEffect(() => {
    dispatch(getCompanyConf());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onEdit = (newObj: any) => {
    setUpdateObj({ ...updateObj, ...newObj });
  };

  return (
    <div className="EditAccount">
      <h2>
        <img src="/assets/images/addaccount.svg" alt="Add Account" />
        Account
      </h2>
      <h3>{accountInfo.display_name}</h3>
      <div className="scrollbar-common">
        <div className="main-scrollable-area">
          <div style={{ height: '100%', overflow: 'auto' }}>
            {isEditMode ? <EditMSSP onChange={onEdit} /> : <ViewMSSP />}
          </div>
        </div>
      </div>
      <div className={classNames('actions', { 'edit-mode': isEditMode })}>
        {flags.isMsspAddAccount ? (
          <div className="edit-trigger" onClick={() => setIsEditMode(true)}>
            <img src="/assets/images/edit-dark.svg" alt="Edit" /> Edit Account
          </div>
        ) : (
          <div></div>
        )}
        {/* <div
          className="deactivate-account"
          onClick={() => {
            dispatch(toggleDeactivateModal());
          }}
        >
          Deactivate Account
        </div> */}
        {isEditMode ? (
          <Button
            type="button"
            buttonStyle="main"
            size="medium"
            onClick={async () => {
              setLoading(true);
              const res = await dispatch(
                editCompany({
                  companyName: accountInfo.name,
                  changeParams: updateObj
                })
              );
              setLoading(false);
              if (res.error) {
                dispatch(showError(res.error.message));
              } else {
                dispatch(showSuccess('New settings saved successfully'));
                dispatch(getRows());
                dispatch(getAccountInfo(accountInfo.name));
                setIsEditMode(false);
              }
            }}
            text="Save Changes"
            disabled={loading || !Object.keys(updateObj).length}
          />
        ) : (
          <a
            href={`/pages/${
              accountInfo.scan_status === 'Seed Data Ready' && flags.isMsspAddAccount
                ? 'settings/seed-data'
                : 'dashboard'
            }/?account=${accountInfo.name}`}
            target="_blank"
            rel="noreferrer"
          >
            <Button type="button" buttonStyle="main" size="medium" onClick={() => {}} text="Login" />
          </a>
        )}
      </div>
    </div>
  );
}
