import { useState } from 'react';
import { Popover } from '@mui/material';
import { Text } from '@cyberpion/cyberpion-ui';
import { makeStyles } from '@material-ui/core/styles';
import { RestApi } from 'common/services/rest-api.service';
import { Common } from 'common/services/common.service';
import { setShouldOpenPopsideCommentsTab } from 'features/GridSlice';
import { useDispatch } from 'react-redux';
// import Tooltip from '@mui/material/Tooltip';
import './Comments.scss';
import { useHistory } from 'react-router-dom';

const iconWithComments = (
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask id="path-1-outside-1_12530_29856" maskUnits="userSpaceOnUse" x="0" y="0" width="14" height="14" fill="black">
      <rect fill="white" width="14" height="14" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2.09848 10.4614C1.40657 9.48334 1 8.28915 1 7C1 3.68629 3.68629 1 7 1C10.3137 1 13 3.68629 13 7C13 10.3137 10.3137 13 7 13C5.68895 13 4.47612 12.5795 3.489 11.866L1.7059 12.2939L2.09848 10.4614Z"
      />
    </mask>
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M2.09848 10.4614C1.40657 9.48334 1 8.28915 1 7C1 3.68629 3.68629 1 7 1C10.3137 1 13 3.68629 13 7C13 10.3137 10.3137 13 7 13C5.68895 13 4.47612 12.5795 3.489 11.866L1.7059 12.2939L2.09848 10.4614Z"
      fill="#3455DD"
    />
    <path
      d="M2.09848 10.4614L2.91483 9.88381L3.16817 10.2419L3.07629 10.6708L2.09848 10.4614ZM3.489 11.866L3.25567 10.8936L3.70244 10.7864L4.0748 11.0556L3.489 11.866ZM1.7059 12.2939L1.93923 13.2663L0.395553 13.6367L0.728088 12.0844L1.7059 12.2939ZM2 7C2 8.07559 2.33849 9.06914 2.91483 9.88381L1.28212 11.0389C0.474649 9.89755 0 8.50271 0 7H2ZM7 2C4.23858 2 2 4.23858 2 7H0C0 3.13401 3.13401 0 7 0V2ZM12 7C12 4.23858 9.76142 2 7 2V0C10.866 0 14 3.13401 14 7H12ZM7 12C9.76142 12 12 9.76142 12 7H14C14 10.866 10.866 14 7 14V12ZM4.0748 11.0556C4.89714 11.6499 5.90622 12 7 12V14C5.47169 14 4.0551 13.5091 2.90321 12.6765L4.0748 11.0556ZM1.47257 11.3215L3.25567 10.8936L3.72233 12.8384L1.93923 13.2663L1.47257 11.3215ZM3.07629 10.6708L2.68372 12.5034L0.728088 12.0844L1.12066 10.2519L3.07629 10.6708Z"
      fill="#3455DD"
      mask="url(#path-1-outside-1_12530_29856)"
    />
    <path d="M10 6H5" stroke="white" stroke-linecap="round" />
    <path d="M8 8H5" stroke="white" stroke-linecap="round" />
  </svg>
);

const iconCommentsAdd = (
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask id="path-1-outside-1_12530_29862" maskUnits="userSpaceOnUse" x="0" y="0" width="14" height="14" fill="black">
      <rect fill="white" width="14" height="14" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2.09848 10.4614C1.40657 9.48334 1 8.28915 1 7C1 3.68629 3.68629 1 7 1C10.3137 1 13 3.68629 13 7C13 10.3137 10.3137 13 7 13C5.68895 13 4.47612 12.5795 3.489 11.866L1.7059 12.2939L2.09848 10.4614Z"
      />
    </mask>
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M2.09848 10.4614C1.40657 9.48334 1 8.28915 1 7C1 3.68629 3.68629 1 7 1C10.3137 1 13 3.68629 13 7C13 10.3137 10.3137 13 7 13C5.68895 13 4.47612 12.5795 3.489 11.866L1.7059 12.2939L2.09848 10.4614Z"
      fill="#D1D2D3"
    />
    <path
      d="M2.09848 10.4614L2.91483 9.88381L3.16817 10.2419L3.07629 10.6708L2.09848 10.4614ZM3.489 11.866L3.25567 10.8936L3.70244 10.7864L4.0748 11.0556L3.489 11.866ZM1.7059 12.2939L1.93923 13.2663L0.395553 13.6367L0.728088 12.0844L1.7059 12.2939ZM2 7C2 8.07559 2.33849 9.06914 2.91483 9.88381L1.28212 11.0389C0.474649 9.89755 0 8.50271 0 7H2ZM7 2C4.23858 2 2 4.23858 2 7H0C0 3.13401 3.13401 0 7 0V2ZM12 7C12 4.23858 9.76142 2 7 2V0C10.866 0 14 3.13401 14 7H12ZM7 12C9.76142 12 12 9.76142 12 7H14C14 10.866 10.866 14 7 14V12ZM4.0748 11.0556C4.89714 11.6499 5.90622 12 7 12V14C5.47169 14 4.0551 13.5091 2.90321 12.6765L4.0748 11.0556ZM1.47257 11.3215L3.25567 10.8936L3.72233 12.8384L1.93923 13.2663L1.47257 11.3215ZM3.07629 10.6708L2.68372 12.5034L0.728088 12.0844L1.12066 10.2519L3.07629 10.6708Z"
      fill="#D1D2D3"
      mask="url(#path-1-outside-1_12530_29862)"
    />
    <path d="M7 9L7 5" stroke="white" stroke-linecap="round" />
    <path d="M9 7L5 7" stroke="white" stroke-linecap="round" />
  </svg>
);

const iconAddGray = (
  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
    <mask id="path-1-outside-1_13101_39091" maskUnits="userSpaceOnUse" x="0" y="0" width="14" height="14" fill="black">
      <rect fill="white" width="14" height="14" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2.09848 10.4614C1.40657 9.48334 1 8.28915 1 7C1 3.68629 3.68629 1 7 1C10.3137 1 13 3.68629 13 7C13 10.3137 10.3137 13 7 13C5.68895 13 4.47612 12.5795 3.489 11.866L1.7059 12.2939L2.09848 10.4614Z"
      />
    </mask>
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M2.09848 10.4614C1.40657 9.48334 1 8.28915 1 7C1 3.68629 3.68629 1 7 1C10.3137 1 13 3.68629 13 7C13 10.3137 10.3137 13 7 13C5.68895 13 4.47612 12.5795 3.489 11.866L1.7059 12.2939L2.09848 10.4614Z"
      fill="#5D6576"
    />
    <path
      d="M2.09848 10.4614L2.91483 9.88381L3.16817 10.2419L3.07629 10.6708L2.09848 10.4614ZM3.489 11.866L3.25567 10.8936L3.70244 10.7864L4.0748 11.0556L3.489 11.866ZM1.7059 12.2939L1.93923 13.2663L0.395553 13.6367L0.728088 12.0844L1.7059 12.2939ZM2 7C2 8.07559 2.33849 9.06914 2.91483 9.88381L1.28212 11.0389C0.474649 9.89755 0 8.50271 0 7H2ZM7 2C4.23858 2 2 4.23858 2 7H0C0 3.13401 3.13401 0 7 0V2ZM12 7C12 4.23858 9.76142 2 7 2V0C10.866 0 14 3.13401 14 7H12ZM7 12C9.76142 12 12 9.76142 12 7H14C14 10.866 10.866 14 7 14V12ZM4.0748 11.0556C4.89714 11.6499 5.90622 12 7 12V14C5.47169 14 4.0551 13.5091 2.90321 12.6765L4.0748 11.0556ZM1.47257 11.3215L3.25567 10.8936L3.72233 12.8384L1.93923 13.2663L1.47257 11.3215ZM3.07629 10.6708L2.68372 12.5034L0.728088 12.0844L1.12066 10.2519L3.07629 10.6708Z"
      fill="#5D6576"
      mask="url(#path-1-outside-1_13101_39091)"
    />
    <path d="M7 9L7 5" stroke="white" stroke-linecap="round" />
    <path d="M9 7L5 7" stroke="white" stroke-linecap="round" />
  </svg>
);

const optionsTime: Intl.DateTimeFormatOptions = {
  hour: 'numeric',
  minute: 'numeric',
  hour12: true
};

const useStyles = makeStyles({
  popoverRoot: {
    marginTop: '10px',
    maxHeight: 400,
    minWidth: 400,
    padding: '20px 0px 14px 20px',
    overflow: 'hidden',
    border: '1px solid #ecedff',
    borderRadius: 6,
    boxShadow:
      '0px 77.291px 61.833px 0px rgba(108, 73, 172, 0.07), 0px 32.29px 25.832px 0px rgba(108, 73, 172, 0.05), 0px 17.264px 13.811px 0px rgba(108, 73, 172, 0.04), 0px 9.678px 7.742px 0px rgba(108, 73, 172, 0.04), 0px 5.14px 4.112px 0px rgba(108, 73, 172, 0.03), 0px 2.139px 1.711px 0px rgba(108, 73, 172, 0.02)',
    '& .title': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginBottom: 6,
      marginRight: 20,
      '& .icon-add': {
        display: 'flex',
        padding: '5px',
        borderRadius: 6,
        border: '1px solid #DADDE0',
        boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.20)',
        '&:hover': {
          backgroundColor: '#e7e7e7',
          cursor: 'pointer'
        }
      }
    },
    '& .comments-wrapper': {
      overflowY: 'auto',
      maxHeight: 300,
      marginRight: 2
    },
    '& .comments-content': {
      display: 'flex',
      flexDirection: 'column',
      marginBottom: 6
    }
  }
});

export function Comments(props: any) {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [commentsList, setCommentsList] = useState<any>(null);
  const [count, setCount] = useState<any>(null);
  const dispatch = useDispatch<any>();
  const history = useHistory();
  const activeTab = history.location.pathname.split('/').pop();

  const classes = useStyles();

  const handleOnClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    const url = Common.AddMsspAccountIfNeeded(`remediation/action-items/${activeTab}/${props.row.id}/comments/`);

    RestApi.getData(url).subscribe((response: any) => {
      const userComments = response.results.filter((comment: any) => comment.activity_source === 'USER_COMMENT');
      setCommentsList(userComments);
      setCount(userComments.length);
      setAnchorEl(event.target as HTMLButtonElement);
    });
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleAddOnClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(null);
    dispatch(setShouldOpenPopsideCommentsTab({ isOpen: true, id: props.row.id }));
  };

  const open = Boolean(anchorEl);
  return (
    <div className="Comments">
      {!!props.row.user_comments_count && (
        <div className="comments-cell" onClick={handleOnClick}>
          <div className="icon">{iconWithComments}</div>
          <Text textSize={12} textColor="#4A79E7" style={{ marginLeft: 6 }}>
            {props.row.user_comments_count}
          </Text>
        </div>
      )}
      {!props.row.user_comments_count && commentsList === null && (
        <div className="comments-cell" onClick={handleAddOnClick}>
          <div className="icon">{iconCommentsAdd}</div>
        </div>
      )}
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        classes={{ paper: classes.popoverRoot }}
        disableScrollLock={true}
      >
        <div>
          {!!commentsList && !!commentsList.length && (
            <div className="title">
              <Text textColor="#25262B" weight={500} textSize={15}>
                {`Comments (${count})`}
              </Text>
              <div className="icon-add" onClick={handleAddOnClick}>
                {iconAddGray}
              </div>
            </div>
          )}
          <div className="comments-wrapper">
            {!!commentsList &&
              !!commentsList.length &&
              commentsList.map((comment: any) => {
                const y = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(new Date(comment.updated_at));
                const m = new Intl.DateTimeFormat('en', { month: 'short' }).format(new Date(comment.updated_at));
                const d = new Intl.DateTimeFormat('en', { day: 'numeric' }).format(new Date(comment.updated_at));
                return (
                  <div className="comments-content" key={comment.id}>
                    <div className="comments-item-date">
                      <div
                        style={{
                          marginTop: 12,
                          display: 'flex',
                          alignItems: 'center'
                        }}
                      >
                        <div
                          style={{
                            borderRadius: '50%',
                            backgroundColor: '#6285F4',
                            minWidth: 8,
                            minHeight: 8,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                          }}
                        >
                          {/* {iconUserComment} */}
                        </div>
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center'
                          }}
                        >
                          {/* <Tooltip
                            title={`${comment.activity_user}, ${m} ${d}, ${y} ${new Intl.DateTimeFormat(
                              'en-US',
                              optionsTime
                            ).format(new Date(comment.created_at))}`}
                            placement="bottom-start"
                            componentsProps={{
                              tooltip: {
                                sx: {
                                  bgcolor: '#4D4C53',
                                  color: '#FFFFFF',
                                  padding: '3px 6px',
                                  marginBottom: '0px !important',
                                  '& .MuiTooltip-arrow': {
                                    color: '#4D4C53'
                                  },
                                  border: '1px solid black',
                                  borderRadius: 1,
                                  zIndex: '9999999999 !important'
                                }
                              }
                            }}
                          > */}
                          <div>
                            <Text
                              textColor="#8C909A"
                              textSize={13}
                              style={{
                                marginLeft: 8,
                                width: 340,
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis'
                              }}
                            >{`${comment.activity_user}, ${m} ${d}, ${y} ${new Intl.DateTimeFormat(
                              'en-US',
                              optionsTime
                            ).format(new Date(comment.created_at))}`}</Text>
                          </div>
                          {/* </Tooltip> */}
                        </div>
                      </div>
                    </div>

                    <Text
                      textSize={14}
                      textColor="#454549"
                      style={{ marginTop: 4, marginLeft: 16, maxWidth: 340, lineHeight: 1.5 }}
                    >
                      {comment.activity}
                    </Text>
                  </div>
                );
              })}
          </div>
        </div>
      </Popover>
    </div>
  );
}
