import './AddAccountConfirmation.scss';
import { Button } from '@cyberpion/cyberpion-ui';
import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { ConfirmedMessage } from './ConfirmedMessage/ConfirmedMessage';
import { AppLoading } from 'common/components/appLoading/appLoading';
import { useDispatch } from 'react-redux';
import { scanCompany, toggleErrorModal } from 'features/MSSPSlice';
import { getRows } from 'features/GridSlice';

export function AddAccountConfirmation(props: any) {
  const [visible, setVisible] = useState<Boolean>(false);
  const [showConfirmation, setShowConfirmation] = useState<Boolean>(false);
  const [loading, setLoading] = useState<Boolean>(false);
  const dispatch = useDispatch<any>();

  useEffect(() => {
    setVisible(props.isVisible);
  }, [props.isVisible]);

  const onSubmit = async () => {
    setLoading(true);
    let data = {};
    if (props.isNotFound) {
      data = props.data;
    } else {
      data = props.data.payload;
    }

    const res = await dispatch(scanCompany(data));

    if (!res.error && typeof res.payload !== 'string' && !res.payload?.detail) {
      setLoading(false);
      setShowConfirmation(true);
      dispatch(getRows());
    } else {
      setLoading(false);
      props.onCancel();
      dispatch(toggleErrorModal(typeof res.payload === 'string' ? res.payload : ''));
    }
  };

  return (
    <div className={classNames('AddAccountConfirmation', { visible: visible })}>
      <div className="scrollbar-common">
        <div className="main-scrollable-area">
          <div style={{ height: '100%', overflow: 'auto' }}>
            <h3>
              {props.isNotFound
                ? 'Could not find a matching company. Do you want to proceed?'
                : 'New Account Confirmation'}
            </h3>
            {showConfirmation ? (
              <ConfirmedMessage onClose={props.onClose} />
            ) : props.isNotFound ? (
              <div>
                <div className="details-box">
                  <h6>Company name</h6>
                  <ul>
                    <li>{props.data.main_name}</li>
                  </ul>
                </div>
              </div>
            ) : (
              <div>
                <p>
                  Please confirm these details. Once confirmed, we will send your request to open the account for our
                  approval.
                </p>
                <h5>Details</h5>
                <div className="details-box">
                  <h6>Company names</h6>
                  <ul>
                    <li>{props.data.payload?.main_name}</li>
                  </ul>
                </div>
                <div className="details-box">
                  <h6>Web addresses</h6>
                  <ul>
                    <li>{props.data.payload?.main_domain}</li>
                  </ul>
                </div>
                {props.data.payload?.company_bio?.countries && (
                  <div className="details-box">
                    <h6>Country</h6>
                    <ul>
                      <li>{props.data.payload.company_bio.countries.join(', ')}</li>
                    </ul>
                  </div>
                )}
                {props.data.payload?.company_bio?.ticker && (
                  <div className="details-box">
                    <h6>Ticker</h6>
                    <ul>
                      <li>{props.data.payload.company_bio.ticker?.join(', ')}</li>
                    </ul>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
      {!showConfirmation && (
        <div className={classNames('buttons-container', { loading: loading })}>
          {loading && (
            <div style={{ height: '100%', width: 100, position: 'absolute', top: '-10px' }}>
              <AppLoading transparent />
            </div>
          )}
          <Button
            text="Go back to account details"
            onClick={props.onCancel}
            type="button"
            buttonStyle="secondary"
            size={'medium'}
          />
          <Button text="Submit & Setup account" onClick={onSubmit} type="button" buttonStyle="main" size={'medium'} />
        </div>
      )}
    </div>
  );
}
