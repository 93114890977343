import { useState, useRef, useEffect } from 'react';
import './grid.scss';
import {
  DataGridPro,
  GridApi,
  GridRowParams,
  GridColumnResizeParams,
  GridSortModel,
  GridSelectionModel
} from '@mui/x-data-grid-pro';
import { GridUtilities } from './components/gridUtilities/GridUtilities';
import { IGrid } from './interface';
import { queryBuilder } from './query-builder.service';
import { ManageNotData } from '../manageNotData/ManageNotData';
import { AppLoading } from '../appLoading/appLoading';
import { useSelector, useDispatch } from 'react-redux';
import {
  addNextPageRows,
  getRows,
  getNextPage,
  sort,
  inputSearch,
  addFilter,
  setQueryString,
  resetCount,
  setFields,
  clearAllFilters
} from 'features/GridSlice';
import { urlToStateConverter } from 'common/services/UrlToStateConverter.service';
import { ColumnsManage } from './columnsManage';
import { SavedViews } from './components/SavedViews/SavedViews';
import InfoPanel from './components/InfoPanel/InfoPanel';
import { RowCount } from './components/rowCount/RowCount';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useLocation } from 'react-router-dom';
import { Text } from '@cyberpion/cyberpion-ui';
import { IReduxState } from 'app/store';
import { setGlobalFilter } from 'features/GeneralSlice';
import { selectView, setIsEdited, setIsOpenSaveViews } from 'features/ViewsSlice';
import { resetInfoPanel } from 'features/InfoPanelSlice';
import { Common } from 'common/services/common.service';
import { styled } from '@mui/material/styles';

let PROMISE: any;

const StyledDataGridPro = styled(DataGridPro)(({ theme }) => ({
  '& .MuiDataGrid-cellCheckbox': {
    '& .MuiSvgIcon-root': {
      width: '18px !important',
      height: '18px !important'
    }
  },
  '& .MuiDataGrid-columnHeader': {
    '& .MuiSvgIcon-root': {
      width: '18px !important',
      height: '18px !important',
      color: '#8C909A90'
    },
    '& .Mui-checked > .MuiSvgIcon-root': {
      color: '#4A79E7'
    }
  },
  '& .MuiDataGrid-row': {
    backgroundColor: '#FFFFFF !important',

    '.MuiCheckbox-root > .MuiSvgIcon-root': {
      color: '#8C909A90'
    },
    '.grid-link-field, .Comments, .info-panel-count, a > .grade-render-scope': {
      color: '#4A79E7',
      padding: '4px 6px',
      borderRadius: 4,
      backgroundColor: '#F9FAFF !important'
    },

    '&:hover': {
      backgroundColor: '#F7F7F7 !important',

      '& .MuiDataGrid-cell': {
        '.grid-link-field, .Comments, .info-panel-count, a > .grade-render-scope': {
          backgroundColor: '#F1F1F1 !important',
          '&:hover': {
            backgroundColor: '#F0ECEC !important'
          }
        },
        '& .MuiCheckbox-root': {
          // color: 'blue',
          '&:hover': {
            '& .MuiSvgIcon-root': {
              color: '#5B76D5'
            }
          }
        }
      }
    },

    '&.Mui-selected': {
      backgroundColor: '#F5F6FF !important',

      '.MuiCheckbox-root > .MuiSvgIcon-root': {
        color: '#1876D2'
      },
      '&:hover': {
        backgroundColor: '#ECEFFE !important'
      },
      '& .MuiDataGrid-cell': {
        '.grid-link-field, .Comments, .info-panel-count, a > .grade-render-scope': {
          backgroundColor: '#E2E3FD !important',
          '&:hover': {
            backgroundColor: '#D6D7FB !important'
          }
        }
      }
    }
  }
}));

export function Grid(props: IGrid) {
  const apiRef: any = useRef<GridApi>();
  const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([]);
  const [isResizing, setIsResizing] = useState<boolean>(false);
  const [csvDownloadPath, setCsvDownloadPath] = useState<null | string>('');
  const {
    rows,
    loading,
    nextPageRows,
    nextPath,
    count,
    order,
    filters,
    search,
    fields,
    allowURLChange,
    capturedState
  } = useSelector((state: any) => state.grid);
  const { globalFilter } = useSelector((state: any) => state.general);
  const { viewSelected } = useSelector((state: any) => state.views);
  const { filtersResetDefault } = useSelector((state: IReduxState) => state.grid);
  const { isOpenInfoPanel } = useSelector((state: IReduxState) => state.infoPanel);
  const { isShowSavedViews } = useSelector((state: any) => state.views);

  const location = useLocation();

  const dispatch = useDispatch<any>();
  let mouseUpEventTimer: ReturnType<typeof setTimeout>;

  const flags = useFlags();

  const setMouseUpEvent = (): void => {
    mouseUpEventTimer = setTimeout(() => {
      setIsResizing(false);
      clearTimeout(mouseUpEventTimer);
    }, 100);
  };

  const handleUrlChange = () => {
    const search = urlToStateConverter.getSearch();
    const order = urlToStateConverter.getSort();
    const filters = urlToStateConverter.getFilters();
    const fields = urlToStateConverter.getFields();
    const group = urlToStateConverter.getGroup();
    if (group) {
      dispatch(setGlobalFilter(group));
    }
    dispatch(inputSearch(search));
    if (fields) {
      const _fields = Array.isArray(fields) ? fields : fields.split(',');
      dispatch(setFields(_fields));
      ColumnsManage.hideColumns(apiRef, props.columns, _fields);
      ColumnsManage.showSpecificColumns(apiRef, props.columns, _fields);
    }
    dispatch(sort(order));

    if (Object.keys(filters).length) {
      for (let filter in filters) {
        dispatch(addFilter({ [filter]: filters[filter] }));
      }
    } else {
      dispatch(clearAllFilters());
    }
  };

  useEffect(() => {
    if (!allowURLChange) {
      return;
    }
    handleUrlChange();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, props.path]);

  useEffect(() => {
    if (viewSelected || filtersResetDefault) {
      handleUrlChange();
      dispatch(getRows());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  useEffect(() => {
    if (!allowURLChange) {
      return;
    }
    urlToStateConverter.setQueryString(filters, search, order, globalFilter, fields);
    dispatch(setQueryString({ tab: props.pageTab, qs: window.location.search }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, fields, search, order, globalFilter, dispatch]);

  useEffect(() => {
    if (PROMISE) {
      PROMISE.abort();
    }
    dispatch(resetCount());
    dispatch(resetInfoPanel());
    dispatch(setIsOpenSaveViews(false));
    dispatch(setIsEdited(false));
    PROMISE = dispatch(getRows());
  }, [dispatch, props.path]);

  useEffect(() => {
    if (nextPath) {
      dispatch(getNextPage());
    }
  }, [dispatch, nextPath]);

  useEffect(() => {
    if (rows.length) {
      const currentQuery = queryBuilder.getQuery();
      const csvAPI = currentQuery.split('api/v1/')[1];
      if (selectionModel.length < 100) {
        setCsvDownloadPath(prepareCSVDownloadPath(csvAPI + `&${props.identifier}__in=${selectionModel.join(',')}`));
      } else {
        setCsvDownloadPath(prepareCSVDownloadPath(csvAPI));
      }
      PROMISE = undefined;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rows, selectionModel, props.identifier]);

  useEffect(() => {
    // Added this to prevent a known DataGrid bug where
    // Column is triggering sorting after column resize
    document.addEventListener('mouseup', setMouseUpEvent);

    return () => {
      document.removeEventListener('mouseup', setMouseUpEvent);
      clearTimeout(mouseUpEventTimer);
    };
    // #TODO: its ok for now, let's find a new approach when refactoring
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOnRowsScrollEnd = () => {
    if (nextPageRows.length) {
      dispatch(addNextPageRows());
    }
  };

  const prepareCSVDownloadPath = (path: any): string | null => {
    return Common.AddMsspAccountIfNeeded(`${path}&format=csv`);
  };

  //@param: model = The model used for sorting the grid
  //@description: Sort by column. And a new request for sorted data
  const handleOnSortModelChange = (model: GridSortModel) => {
    if (isResizing) {
      return;
    }
    if (model[0]) {
      const orderString = model[0].sort === 'asc' ? `-${model[0].field}` : model[0].field;
      if (order !== orderString) {
        dispatch(setIsEdited(true));
        dispatch(selectView(null));
        dispatch(sort(orderString));
        dispatch(getRows());
      }
    }
  };

  // const handleOnFilterModelChange = (params: GridFilterModel) => {};
  const handleOnColumnResize = (params: GridColumnResizeParams) => {
    setIsResizing(true);
  };

  const handleOnSelectionModelChange = (model: GridSelectionModel) => {
    // Description:
    // We have 2 different Ids for each row in the table (id, _id)
    // Material UI needs the <_id> to manage the selected rows,
    // Our API needs the <id> to identify the selected rows.
    // const selectedIDs = new Set(model);

    // This creates a new array with the ids that we'll later use to make api calls
    // const selectedRowsIds = rows.filter((row: any) =>
    //   selectedIDs.has(row._id.toString())
    // ).map((row: any) => row.id);

    // setSubsidiarySelectedIds(selectedRowsIds); // For API calls
    setSelectionModel(model); // For Material DataGridPro
  };

  const _getRows = () => {
    if (props.isPopup) {
      return rows[0] && rows[0][props.identifier] ? rows : [];
    } else if (Object.keys(capturedState).length) {
      return capturedState.rows[0] && capturedState.rows[0][props.identifier] ? capturedState.rows : [];
    } else {
      return rows[0] && rows[0][props.identifier] ? rows : [];
    }
  };

  const getOrder = () => {
    const order = urlToStateConverter.getSort();
    if (order) {
      const _order = {
        field: order.replace('-', ''),
        sort: order.includes('-') ? 'asc' : 'desc'
      };
      return { ...props.initialState, sorting: { sortModel: [{ field: _order.field, sort: _order.sort }] } };
    }
    return props.initialState || {};
  };

  const handleOnRowDoubleClick = (params: GridRowParams) => {
    if (typeof props.onRowDoubleClick === 'function') {
      props.onRowDoubleClick(params);
    }
  };

  return (
    <div className="grid" style={{ display: 'flex', flexDirection: 'column' }}>
      <div className="grid-wrapper">
        {props.showInfoPanel && !loading && (
          <div style={{ height: 40, display: 'flex', padding: 10, alignItems: 'center' }}>
            <img src="/assets/images/info.svg" className="info" alt="evidence info" />
            <Text style={{ paddingLeft: 10 }} textSize={13} textColor="#6d6d7a">
              IONIX employs advanced classification techniques and Machine Learning algorithms in the discovery process.
              The table below presents dominant features that attribute the domain to the organization.
            </Text>
          </div>
        )}
        <GridUtilities
          apiGrid={apiRef}
          isMoreButton={props.isMoreButton}
          isCSVDownloadable={props.isCSVDownloadable}
          isActionItemsPDFDownloadable={props.isActionItemsPDFDownloadable}
          // showColumnPicker={props.showColumnPicker}
          // columnPicker={props.columnPicker}
          // selectionRows={selectedIds}
          selectionModel={selectionModel}
          buttonSelectionText={props.buttonSelectionText}
          elementSelect={props.elementSelect}
          loadedRows={rows}
          columns={props.columns}
          // hideOrShowColumnsGrid={handleOnHideOrShowColumnsGrid}
          onSelectionChange={props.onSelectionRowsChange}
          // onUpdateFilterGrid={handleUpdateFilterGrid}
          downloadCSVPath={csvDownloadPath}
          addedActions={props.addedActions}
          identifier={props.identifier}
          page={props.page}
          pageTab={props.pageTab}
          savedViews={props.savedViews}
        />
        <div className="grid-scope">
          {/* <RowCount conut={count} typeData={'Assets'} /> */}
          <div
            style={{ height: '100%', width: '100%', background: '#FFFFFF', position: 'relative', display: 'flex' }}
            data-testid="data-grid-pro"
          >
            {flags.isSavedViews && apiRef.current && (
              <SavedViews
                open={isShowSavedViews}
                apiGrid={apiRef}
                columns={props.columns}
                page={props.page}
                tab={props.pageTab}
                onBackToDefault={handleUrlChange}
              />
            )}
            <div style={{ position: 'relative', flex: 1 }}>
              {loading && <AppLoading />}

              <RowCount conut={count} typeData={'Assets'} selectedCount={selectionModel.length} />
              <StyledDataGridPro
                components={{
                  NoRowsOverlay: () => <ManageNotData />
                }}
                apiRef={apiRef}
                rows={_getRows()}
                columns={props.columns}
                onRowDoubleClick={handleOnRowDoubleClick}
                // onFilterModelChange={handleOnFilterModelChange}
                onSortModelChange={handleOnSortModelChange}
                onRowsScrollEnd={handleOnRowsScrollEnd}
                onColumnResize={handleOnColumnResize}
                onSelectionModelChange={handleOnSelectionModelChange}
                selectionModel={selectionModel}
                filterMode="server"
                sortingMode="server"
                disableMultipleColumnsSorting={true}
                disableSelectionOnClick={true}
                disableColumnReorder={true}
                checkboxSelection
                disableColumnMenu={true}
                hideFooter={true}
                getRowId={row => row[props.identifier]}
                initialState={getOrder()}
              />
            </div>
            <InfoPanel open={isOpenInfoPanel} />
          </div>
        </div>
      </div>
    </div>
  );
}
