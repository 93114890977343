import { LabelWithConfidence } from '@cyberpion/cyberpion-ui';
import './SeedDataAdditionalInfo.scss';
import { useClickOutside } from 'hooks/useClickOutside';
import { useRef } from 'react';
import { CONFIDENCE_LEVEL } from '../DragDrop';
import classNames from 'classnames';

const CONF_DICT: { [key: string]: string } = {
  verylow: 'Very Low',
  low: 'Low',
  moderate: 'Moderate',
  high: 'High',
  veryhigh: 'Very High'
};

export function SeedDataAdditionalInfo(props: any) {
  const ref = useRef(null);
  useClickOutside(ref, props.onClose);

  if (props.type === 'domain') {
    return (
      <div className="SeedDataAdditionalInfo" style={props.style} ref={ref}>
        <h4 className={classNames('domain', { small: props.item.domain.length > 20 })}>
          {!!props.item.additional_data.favicon_links?.length && (
            <img src={props.item.additional_data.favicon_links[0]} alt="Favicon" />
          )}
          {props.item.domain}
        </h4>
        <div className="scrollbar-common">
          <div style={{ overflowY: 'auto', maxHeight: 150 }}>
            <div style={{ padding: 20, paddingTop: 0 }}>
              {props.item.additional_data.whois_names?.length && (
                <h6 style={{ margin: '0 0 6px 0', fontSize: 12 }}>Whois Names</h6>
              )}
              {props.item.additional_data.whois_names?.map((wn: string) => {
                return <div style={{ margin: 0, fontSize: 12 }}>{wn}</div>;
              })}
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="SeedDataAdditionalInfo" style={props.style} ref={ref}>
        <h4 className={classNames({ small: props.item.name.length > 20 })}>{props.item.name}</h4>
        <div className="scrollbar-common">
          <div style={{ overflowY: 'auto', maxHeight: 150 }}>
            <div style={{ padding: 20, paddingTop: 0 }}>
              <LabelWithConfidence
                level={CONFIDENCE_LEVEL[props.item.confidence]}
                label={`${CONF_DICT[CONFIDENCE_LEVEL[props.item.confidence]]} Confidence`}
              />
              {(!!props.parentEntity?.additional_data?.countries?.length ||
                !!props.parentEntity?.additional_data?.social_network_links?.length) && <h5>Details</h5>}
              <ul>
                {!!props.parentEntity?.additional_data?.countries?.length && (
                  <li>
                    <h6>Country</h6>
                    <p>{props.parentEntity?.additional_data?.countries?.join(', ')}</p>
                  </li>
                )}
                {!!props.parentEntity?.additional_data?.social_network_links?.length && (
                  <li>
                    <h6>Social</h6>
                    <div>
                      {props.parentEntity?.additional_data?.social_network_links?.map((link: string) => {
                        return (
                          <div className="link-wrapper">
                            <a href={link} target="_blank" rel="noreferrer">
                              {link}
                            </a>
                          </div>
                        );
                      })}
                    </div>
                  </li>
                )}
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
