import { Button } from '@cyberpion/cyberpion-ui';
import './PromptPopup.scss';
import { AppDispatch, IReduxState } from 'app/store';
import { useDispatch, useSelector } from 'react-redux';
import { hide, runPromptCallback } from 'features/PromptSlice';
import classNames from 'classnames';
import { AppLoading } from '../appLoading/appLoading';
import { showError } from 'features/AlertSlice';
import { Common } from 'common/services/common.service';

export function PromptPopup() {
  const dispatch: AppDispatch = useDispatch();
  const { title, text, ctaText, visible, status, successText, successTitle } = useSelector(
    (state: IReduxState) => state.prompt
  );

  if (status === 'rejected') {
    dispatch(hide());
    dispatch(showError('Something went wrong'));
  }

  return (
    <div className={classNames('PromptPopup', { visible: visible })}>
      <div
        className="bg-layer"
        onClick={() => {
          if (status !== 'success') {
            dispatch(hide());
          }
        }}
      ></div>
      <div className="prompt-content">
        {status === 'loading' ? (
          <AppLoading />
        ) : (
          <div>
            <h3>{status === 'success' ? successTitle : title}</h3>
            <p>{status === 'success' ? successText : text}</p>
            {status !== 'success' && status !== 'loading' && (
              <div className="buttons-container">
                <Button
                  text="Cancel"
                  onClick={() => {
                    dispatch(hide());
                  }}
                  buttonStyle="secondary"
                  size="medium"
                  type="button"
                />
                <Button
                  text={ctaText}
                  onClick={() => {
                    dispatch(runPromptCallback());
                  }}
                  buttonStyle="main"
                  size="medium"
                  type="button"
                />
              </div>
            )}
            {status === 'success' && (
              <div className="buttons-container">
                <Button
                  text="Go To Dashboard"
                  onClick={() => {
                    window.location.href = Common.AddMsspAccountIfNeeded(window.location.origin + '/pages/dashboard/');
                  }}
                  buttonStyle="secondary"
                  size="medium"
                  type="button"
                />
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}
