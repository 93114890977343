import classNames from 'classnames';
import './RunScanButton.scss';
import { Button } from '@cyberpion/cyberpion-ui';
import { useEffect, useRef, useState } from 'react';
import { Checkbox } from 'common/components/Icons/IconCheckbox/IconCheckbox';
import { useClickOutside } from 'hooks/useClickOutside';
import { useDispatch, useSelector } from 'react-redux';
import { setContent } from 'features/PromptSlice';
import { IReduxState } from 'app/store';

export function RunScanButton(props: any) {
  const [scanMenuVisible, setScanMenuVisible] = useState<boolean>(false);
  const [domainDiscovery, setDomainDiscovery] = useState<boolean>(true);
  const [ipDiscovery, setIpDiscovery] = useState<boolean>(true);
  const [managedDomainsDiscovery, setManagedDomainsDiscovery] = useState<boolean>(true);
  const [subDomainsDiscovery, setSubDomainsDiscovery] = useState<boolean>(true);
  const [assessment, setAssessment] = useState<boolean>(true);

  const { user } = useSelector((state: IReduxState) => state.login);

  const ref = useRef(null);
  const dispatch = useDispatch<any>();

  useClickOutside(ref, () => {
    setScanMenuVisible(false);
  });

  useEffect(() => {
    if (user.company_scan_status === 1002)
      dispatch(
        setContent({
          successTitle: 'Scanning in progress...',
          successText: 'You will receive an email notification once the scan is completed. You may close this window.',
          status: 'success'
        })
      );
  }, [user.company_scan_status, dispatch]);

  return (
    <div ref={ref} className={classNames('RunScanButton')}>
      <Button
        text="Send To Scan"
        onClick={() => {
          dispatch(
            setContent({
              title: 'Are you sure?',
              text: 'Once the scan is initiated, the Seed Assets page will be unavailable until the scan is completed.',
              ctaText: 'Submit',
              successTitle: 'Scanning in progress...',
              successText:
                'You will receive an email notification once the scan is completed. You may close this window.',
              callbackData: {
                is_domain_discovery: domainDiscovery,
                is_ip_discovery: ipDiscovery,
                is_managed_discovery: managedDomainsDiscovery,
                is_subdomain_scan: subDomainsDiscovery,
                is_assessment: assessment
              },
              callbackEndpoint: 'settings/seed-data/scan/'
            })
          );
        }}
        buttonStyle="main"
        size="medium"
        type="button"
        disabled={props.disabled}
      />
      <ul className={classNames('scan-menu', { visible: scanMenuVisible })}>
        <li>Scan Types</li>
        <li
          onClick={e => {
            e.preventDefault();
            setDomainDiscovery(!domainDiscovery);
          }}
        >
          <Checkbox active={domainDiscovery} /> Domain Discovery
        </li>
        <li
          onClick={e => {
            e.preventDefault();
            setIpDiscovery(!ipDiscovery);
          }}
        >
          <Checkbox active={ipDiscovery} /> IP Discovery
        </li>
        <li
          onClick={e => {
            e.preventDefault();
            setManagedDomainsDiscovery(!managedDomainsDiscovery);
          }}
        >
          <Checkbox active={managedDomainsDiscovery} /> Managed Domains Discovery
        </li>
        <li
          onClick={e => {
            e.preventDefault();
            setSubDomainsDiscovery(!subDomainsDiscovery);
          }}
        >
          <Checkbox active={subDomainsDiscovery} /> Sub Domains Discovery
        </li>
        <li
          onClick={e => {
            e.preventDefault();
            setAssessment(!assessment);
          }}
        >
          <Checkbox active={assessment} /> Assessment
        </li>
      </ul>
      <Button
        icon="arrow-up"
        text=""
        onClick={() => {
          setScanMenuVisible(!scanMenuVisible);
        }}
        buttonStyle="main"
        size="medium"
        type="button"
        disabled={props.disabled}
      />
    </div>
  );
}
