// @ts-nocheck
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { addHeaders } from 'common/services/rest-api.service';
import { env } from 'env';

export const addNemSubsidiary = createAsyncThunk(
  'subsidiaries/setSubsidiary',
  async (data: any, { rejectWithValue }) => {
    const path = `${env.REACT_APP_BASE_URL}settings/organization-management/subsidiaries/`;

    const res = await fetch(path, {
      method: 'POST',
      mode: 'cors',
      headers: addHeaders('POST'),
      credentials: 'include',
      body: JSON.stringify(data)
    });
    const response = await res.json();

    if (!response.ok) {
      return rejectWithValue({ error: response });
    }
    return response;
  }
);

export const fetchSubsidiaryByName = createAsyncThunk(
  'subsidiaries/fetchSubsidiaryByName',
  async (subsidiaryName: string, { rejectWithValue }) => {
    const path = `${env.REACT_APP_BASE_URL}settings/organization-management/subsidiaries/${subsidiaryName}/`;
    try {
      const response = await fetch(path, {
        method: 'GET',
        mode: 'cors',
        headers: addHeaders('GET'),
        credentials: 'include'
      });

      const data = await response.json();
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchAllSubsidiaries = createAsyncThunk(
  'subsidiaries/fetchAllSubsidiaries',
  async (payload, { rejectWithValue }) => {
    const path = `${env.REACT_APP_BASE_URL}settings/organization-management/subsidiaries/?limit=1000`;
    try {
      const response = await fetch(path, {
        method: 'GET',
        mode: 'cors',
        headers: addHeaders('GET'),
        credentials: 'include'
      });
      const data = await response.json();
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchSummarySubsidiaries = createAsyncThunk(
  'subsidiaries/fetchSummarySubsidiaries',
  async (payload, { rejectWithValue }) => {
    const path = `${env.REACT_APP_BASE_URL}assessments/org_score/summary/subsidiaries/`;
    try {
      const response = await fetch(path, {
        method: 'GET',
        mode: 'cors',
        headers: addHeaders('GET'),
        credentials: 'include'
      });
      const data = await response.json();
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const SubsidiariesSlice = createSlice({
  name: 'subsidiaries',
  initialState: {
    allSubsidiaries: [],
    summarySubsidiaries: [],
    selectedSubsidiary: {},
    activeSubsidiary: {},
    parentCompany: {},
    subsidiaryName: '',
    subsidiariesPopupVisible: false,
    subsidiariesEditMode: false,
    subsidiarySelectedId: -1,
    statusAllSubsidiaries: 'idle',
    statusSubsidiaryByName: 'idle',
    statusSummarySubsidiaries: 'idle'
  },
  reducers: {
    toggleSubsidiariesPopup(state, action) {
      state.subsidiariesPopupVisible = action.payload;
    },
    setSubsidiariesEditMode(state, action) {
      state.subsidiariesEditMode = action.payload;
    },
    setSubsidiarySelectedId(state, action) {
      state.subsidiarySelectedId = action.payload;
    },
    setSubsidiaryName(state, action) {
      state.subsidiaryName = action.payload;
    },
    resetSubsidiaryState(state) {
      state.allSubsidiaries = [];
      state.subsidiarySelectedId = -1;
      state.subsidiariesEditMode = false;
      state.subsidiariesPopupVisible = false;
      state.selectedSubsidiary = {};
      state.activeSubsidiary = {};
      state.parentCompany = {};
      state.statusAllSubsidiaries = 'idle';
      state.statusSubsidiaryByName = 'idle';
      state.statusSummarySubsidiaries = 'idle';
    },

    setParentCompany(state, action) {
      state.parentCompany = action.payload;
    },
    setActiveSubsidiary(state, action) {
      state.activeSubsidiary = action.payload;
    },
    setSubsidiaries(state, action) {
      state.allSubsidiaries = action.payload;
    }
  },
  extraReducers: {
    [fetchSubsidiaryByName.fulfilled]: (state, { payload }) => {
      state.selectedSubsidiary = payload;
      state.statusSubsidiaryByName = 'succeed';
    },
    [fetchSubsidiaryByName.rejected]: (state, action) => {
      state.error = action.error;
      state.statusSubsidiaryByName = 'failed';
    },
    [fetchSubsidiaryByName.pending]: state => {
      state.statusSubsidiaryByName = 'loading';
    },
    [fetchAllSubsidiaries.fulfilled]: (state, { payload }) => {
      state.allSubsidiaries = payload.results;
      state.statusAllSubsidiaries = 'succeed';
    },
    [fetchAllSubsidiaries.rejected]: (state, action) => {
      state.error = action.error;
      state.statusAllSubsidiaries = 'failed';
    },
    [fetchAllSubsidiaries.pending]: state => {
      state.statusSubsidiaries = 'loading';
    },
    [fetchSummarySubsidiaries.fulfilled]: (state, { payload }) => {
      state.summarySubsidiaries = payload.results;
      state.statusSummarySubsidiaries = 'succeed';
    },
    [fetchSummarySubsidiaries.rejected]: (state, action) => {
      state.error = action.error;
      state.statusSummarySubsidiaries = 'failed';
    },
    [fetchSummarySubsidiaries.pending]: state => {
      state.statusSummarySubsidiaries = 'loading';
    }
  }
});

const { actions, reducer } = SubsidiariesSlice;
export const {
  toggleSubsidiariesPopup,
  setSubsidiaryName,
  setSubsidiaries,
  resetSubsidiaryState,
  setSubsidiarySelectedId,
  setSubsidiariesEditMode,
  setActiveSubsidiary,
  setParentCompany
} = actions;

export default reducer;
