// @ts-nocheck
import { createSlice } from '@reduxjs/toolkit';

const popSideSlice = createSlice({
  name: 'popSide',
  initialState: {
    commentsTabCount: 0
  },
  reducers: {
    setCommentsTabCount(state, { payload }) {
      state.commentsTabCount = payload;
    }
  }
});

const { actions, reducer } = popSideSlice;
export const { setCommentsTabCount } = actions;
export default reducer;
