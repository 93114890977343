import { getColorByUrgency, Text } from '@cyberpion/cyberpion-ui';
import { Common } from 'common/services/common.service';
import classNames from 'classnames';
import '../PopSideHeader.scss';

const iconGreater = (
  <svg xmlns="http://www.w3.org/2000/svg" width="5" height="8" viewBox="0 0 5 8" fill="none">
    <path d="M1 1L4 4L1 7" stroke="black" stroke-linecap="round" />
  </svg>
);

const HeaderDetails = ({ data }: { data: any }) => {
  const urgencyColor = getColorByUrgency(data?.severity?.toLowerCase());
  const suggestedUrgencyColor = getColorByUrgency(data?.suggested_new_severity?.toLowerCase());
  // const isClosed = window.location.pathname.includes('/closed');

  return (
    <div className="header-details">
      {/* <div className="left-indicator-line" style={{ backgroundColor: urgencyColor }} /> */}
      <div className="header-urgency">
        <Text
          weight={500}
          family="Rajdhani"
          textSize={12}
          style={{ display: 'flex', justifyContent: 'center', textAlign: 'center', marginBottom: 6 }}
        >
          Source
          <br />
          Severity
        </Text>
        <Text
          textSize={12}
          textColor="#FFF"
          style={{
            backgroundColor: urgencyColor,
            padding: '4px 8px',
            borderRadius: 4,
            textTransform: 'capitalize',
            textAlign: 'center'
          }}
        >
          {data.severity}
        </Text>
      </div>
      {data.suggested_new_severity && (
        <>
          <div className="urgency-with-pointer-wrapper">
            <div className="pointer-right"></div>
          </div>
          <div className="header-urgency">
            <Text
              weight={500}
              family="Rajdhani"
              textSize={12}
              style={{ display: 'flex', justifyContent: 'center', textAlign: 'center', marginBottom: 6 }}
            >
              Adjusted
              <br />
              Severity
            </Text>
            <Text
              textSize={12}
              textColor="#FFF"
              style={{
                backgroundColor: suggestedUrgencyColor,
                padding: '4px 8px',
                borderRadius: 4,
                textTransform: 'capitalize',
                textAlign: 'center'
              }}
            >
              {data.suggested_new_severity}
            </Text>
          </div>
        </>
      )}
      <div style={{ backgroundColor: '#e7e7e7', height: '100%', width: 1 }} />
      <div className="right-side">
        <Text weight={500} textSize={18} style={{ paddingBottom: 8, maxWidth: 670 }}>
          {data.title}
        </Text>
        <div className="chips-details">
          {!!data.creation_time && (
            <Text
              textSize={13}
              textColor="#6D6D7A"
              style={{ padding: '4px 8px', borderRadius: 4, backgroundColor: '#F5F6F8', marginRight: 12 }}
            >
              {`Creation time: ${Common.getShortDate(data.creation_time)}`}
            </Text>
          )}
          {!!data.update_time && (
            <Text
              textSize={13}
              textColor="#6D6D7A"
              style={{ padding: '4px 8px', borderRadius: 4, backgroundColor: '#F5F6F8', marginRight: 12 }}
            >
              {`Update time: ${Common.getShortDate(data.update_time)}`}
            </Text>
          )}
          {!data.last_closed_at && !!data.last_validate && (
            <Text
              textSize={13}
              textColor="#6D6D7A"
              style={{ padding: '4px 8px', borderRadius: 4, backgroundColor: '#F5F6F8', marginRight: 12 }}
            >
              {`Last validated: ${Common.getShortDate(data.last_validate)}`}
            </Text>
          )}
          {!!data.last_closed_at && (
            <Text
              textSize={13}
              textColor="#6D6D7A"
              style={{ padding: '4px 8px', borderRadius: 4, backgroundColor: '#F5F6F8', marginRight: 12 }}
            >
              {`Closed date: ${Common.getShortDate(data.last_closed_at)}`}
            </Text>
          )}
        </div>
      </div>
    </div>
  );
};

export const getSourceImage = (source: string) => {
  if (source.toLowerCase().startsWith('pan')) {
    return 'pan';
  }
  if (source.toLowerCase().startsWith('wiz')) {
    return 'wiz';
  }
};

const CnappAlertHeader = (props: any) => {
  const data: any = props.data;

  const handleNavigateActionItems = (btn: string) => () => {
    props.onNavigate(btn === 'up');
  };

  return (
    <div className="ActionItemsPopSideHeader">
      <div className="header-row">
        <img src="/assets/images/cnapp.svg" alt="CNAPP" />
        <Text textSize={13} weight={500} style={{ marginLeft: 6, paddingRight: 8 }}>
          CNAPP Alerts
        </Text>
        {/* <div className="icon-greater">{iconGreater}</div> */}
        <div className="sides-border">
          <img
            alt={data.source}
            src={`/assets/images/${getSourceImage(data.source)}.svg`}
            style={{ maxWidth: 20, maxHeight: 16 }}
          />
          <Text textSize={13} weight={500} style={{ marginLeft: 6 }}>
            {data.source}
          </Text>
        </div>

        <div className="right-border">
          <img
            alt={data.source}
            src={`/assets/images/${data.cloud_provider.toLowerCase()}.svg`}
            style={{ maxWidth: 20, maxHeight: 16 }}
          />
          <Text
            weight={500}
            textSize={13}
            style={{
              maxWidth: 300,
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              marginLeft: 6
            }}
          >
            {data.cloud_provider}
          </Text>
        </div>
        <Text
          weight={500}
          textSize={13}
          style={{
            marginLeft: 10,
            maxWidth: 300,
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis'
          }}
        >
          {data.ionix_asset}
        </Text>
        <div style={{ flexGrow: 1 }} />

        <div className="action-header-buttons">
          <div
            className={classNames('btn-popside-navigate up', { disabled: props.currentIndex === 0 })}
            onClick={handleNavigateActionItems('up')}
          >
            {iconGreater}
          </div>
          <div
            className={classNames('btn-popside-navigate down', { disabled: props.isLastIndex })}
            onClick={handleNavigateActionItems('down')}
          >
            {iconGreater}
          </div>
        </div>
      </div>
      <HeaderDetails data={data} />
    </div>
  );
};

export default CnappAlertHeader;
