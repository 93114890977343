import { useState, useEffect } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';

export const PLAN_LIMITATIONS_TYPES = {
  UNLIMITED: 'unlimited',
  LIMITED: 'limited'
};

const useAccountPlanLimitations = (FF: string) => {
  const [planLimitation, setPlanLimitation] = useState<any>(null);
  const [limitationsType, setLimitationsType] = useState(PLAN_LIMITATIONS_TYPES.LIMITED);

  const flags = useFlags();

  useEffect(() => {
    if (!!flags && flags[FF]) {
      setPlanLimitation(flags[FF]);
      if (flags[FF] === 999) {
        setLimitationsType(PLAN_LIMITATIONS_TYPES.UNLIMITED);
      }
    }
  }, [flags, FF]);

  return { planLimitation, limitationsType };
};

export default useAccountPlanLimitations;
