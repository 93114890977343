import React from 'react';
import { ThreatsCenterContextType, useThreatsCenter } from 'pages/ThreatCenter/ThreatsCenterContext';
import { Text } from '@cyberpion/cyberpion-ui';
import classNames from 'classnames';
import { Common } from 'common/services/common.service';
import PendingScan from './PendingScan';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useSelector } from 'react-redux';
import { IReduxState } from 'app/store';

const Findings = ({ data }: any) => {
  const { dependentData } = useThreatsCenter() as ThreatsCenterContextType;
  const { user } = useSelector((state: IReduxState) => state.login);
  const flags = useFlags();
  const findings =
    !!dependentData && !!dependentData[data.uuid] && dependentData[data.uuid]?.findings !== 'err'
      ? dependentData[data.uuid]?.findings
      : 0;
  const affected =
    !!dependentData && !!dependentData[data.uuid] && dependentData[data.uuid]?.potential_affected !== 'err'
      ? dependentData[data.uuid]?.potential_affected
      : 0;
  const showFindings = !!dependentData && !!dependentData[data.uuid] && dependentData[data.uuid]?.findings !== 'err';
  const showAffected =
    !!dependentData && !!dependentData[data.uuid] && dependentData[data.uuid]?.potential_affected !== 'err';

  const handleOnClick = (type: string) => () => {
    window.open(
      type === 'findings'
        ? Common.AddMsspAccountIfNeeded(data.findings_ui_url)
        : Common.AddMsspAccountIfNeeded(data.potential_affected_ui_url),
      '_blank'
    );
  };

  const isPending = () => {
    return dependentData && user.company_last_scan_date < data.creation_time && showFindings && !!data.findings_api_url;
  };

  return !!data.findings_api_url || !!data.potential_affected_api_url ? (
    <div style={{ display: 'flex', flexDirection: 'column', minWidth: 180, maxWidth: 180 }}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          paddingRight: 50
        }}
      >
        {isPending() && !flags.isRealtimeThreatCenter ? <PendingScan /> : ''}
        {!!data.findings_api_url &&
          showFindings &&
          (flags.isRealtimeThreatCenter || (!flags.isRealtimeThreatCenter && !isPending())) && (
            <div
              className={classNames('threat-item-findings')}
              onClick={handleOnClick('findings')}
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <Text
                textSize={30}
                weight={500}
                family="Rajdhani"
                className={classNames({ link: !!data.findings_ui_url })}
              >
                {findings}
              </Text>
              <Text textSize={13} textColor="#6D6D7A" style={{ width: 'min-content', marginLeft: 12 }}>
                Confirmed Findings
              </Text>
            </div>
          )}
        {(!!data.findings_api_url || (isPending() && !flags.isRealtimeThreatCenter)) &&
          !!data.potential_affected_api_url && (
            <div style={{ height: 1, backgroundColor: '#E8E8E8', margin: '6px 0' }}></div>
          )}
        {!!!!data.potential_affected_api_url && showAffected && (
          <div
            className={classNames('threat-item-findings', { link: !!data.potential_affected_ui_url })}
            onClick={handleOnClick('potential')}
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <Text
              textSize={30}
              weight={500}
              family="Rajdhani"
              className={classNames({ link: !!data.potential_affected_ui_url })}
            >
              {affected}
            </Text>
            <Text textSize={13} textColor="#6D6D7A" style={{ width: 'min-content', marginLeft: 12 }}>
              Potentially Affected
            </Text>
          </div>
        )}
      </div>
    </div>
  ) : null;
};

export default Findings;
