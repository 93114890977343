import React from 'react';
import { Text, IconRisk } from '@cyberpion/cyberpion-ui';
import { IconImportance } from 'common/components/Icons/IconImportance/IconImportance';
import { Grid } from '@mui/material';

import './actionItemHeader.scss';

interface IActionItemHeader {
  actionItem: any;
}

const ActionItemHeader: React.FC<IActionItemHeader> = ({ actionItem }) => {
  const {
    id,
    urgency,
    title,
    asset_importance,
    asset,
    type,
    groups,
    last_validate,
    last_opened_at,
    first_opened_at
  } = actionItem;
  let lastValidate, lastOpened, opened;
  let y, m, d;

  if (last_validate) {
    y = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(new Date(last_validate));
    m = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(new Date(last_validate));
    d = new Intl.DateTimeFormat('en', { day: 'numeric' }).format(new Date(last_validate));
    lastValidate = `${d}/${m}/${y}`;
  }
  if (first_opened_at) {
    y = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(new Date(first_opened_at));
    m = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(new Date(first_opened_at));
    d = new Intl.DateTimeFormat('en', { day: 'numeric' }).format(new Date(first_opened_at));
    opened = `${d}/${m}/${y}`;
  }
  if (last_opened_at) {
    y = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(new Date(last_opened_at));
    m = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(new Date(last_opened_at));
    d = new Intl.DateTimeFormat('en', { day: 'numeric' }).format(new Date(last_opened_at));
    lastOpened = `${d}/${m}/${y}`;
  }

  return (
    <Grid container alignItems="center">
      <Grid item className="action-item-risk">
        <IconRisk urgency={urgency?.type} />
      </Grid>
      <Grid item className="action-item-urgency">
        <Text textSize={44} weight={400} family="Rajdhani">
          {urgency?.urgency}
        </Text>
      </Grid>
      <Grid item>
        <Grid container flexDirection="column">
          <Grid item id={id}>
            <Text upper textSize={12} weight={600} style={{ lineHeight: 1.5 }}>
              {title?.label}
            </Text>
          </Grid>
          <Grid item className="action-item-header-data">
            <Grid
              container
              alignItems="center"
              sx={!asset_importance ? { paddingTop: '6px', fontSize: 11 } : { fontSize: 11 }}
            >
              {!!asset_importance && (
                <Grid item className="action-item-header-importance">
                  <IconImportance type={asset_importance?.type} />
                </Grid>
              )}
              <Grid item className="action-item-header-host">
                {asset}
              </Grid>
              <Grid item className="action-item-header-type">
                {type}
              </Grid>
              {!!groups && (
                <Grid item className="action-item-header-groups">
                  {groups?.join(', ')}
                </Grid>
              )}
            </Grid>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {!!opened && (
                <Grid item className="action-item-header-last-validated padding-right">{`Open Date: ${opened}`}</Grid>
              )}
              {!!lastOpened && (
                <Grid item className="action-item-header-last-validated border">
                  {`Last Opened Date: ${lastOpened}`}
                </Grid>
              )}
              {!!lastValidate && (
                <Grid item className="action-item-header-last-validated border">
                  {`Last Validated: ${lastValidate}`}
                </Grid>
              )}
            </div>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ActionItemHeader;
