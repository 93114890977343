import { Text } from '@cyberpion/cyberpion-ui';
import classNames from 'classnames';
import '../PopSideTabs.scss';

interface ICNAPPAlertTabs {
  data: any;
  tabActive: string;
  setTabActive: (tab: string) => void;
}
const CNAPPAlertTabs: React.FC<ICNAPPAlertTabs> = ({ data, tabActive, setTabActive }) => {
  const iconGeneral = (color: string) => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.41125 14.1709H12.6005C12.6681 14.1709 12.7351 14.1576 12.7977 14.1317C12.8602 14.1058 12.917 14.0678 12.9648 14.02C13.0127 13.9721 13.0506 13.9153 13.0765 13.8528C13.1024 13.7903 13.1157 13.7233 13.1157 13.6556V4.55225L9.50897 0.945312H3.92655C3.35739 0.945312 2.896 1.40671 2.896 1.97587V11.1661V13.6556C2.896 13.7923 2.95028 13.9234 3.04691 14.02C3.14354 14.1166 3.27459 14.1709 3.41125 14.1709Z"
        stroke={color}
        stroke-width="0.944752"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path d="M9.5 7.5H5.5" stroke={color} stroke-linecap="round" stroke-linejoin="round" />
      <path d="M7.5 9.5H5.5" stroke={color} stroke-linecap="round" stroke-linejoin="round" />
      <path
        d="M9.50879 0.945312V4.55225H13.1157"
        stroke={color}
        stroke-width="0.944752"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );

  const iconRaw = (color: string) => (
    <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.896 6.52856V1.97587C4.896 1.40671 5.35739 0.945312 5.92655 0.945312H11.509L15.1157 4.55225V13.6556C15.1157 13.7233 15.1024 13.7903 15.0765 13.8528C15.0506 13.9153 15.0127 13.9721 14.9648 14.02C14.917 14.0678 14.8602 14.1058 14.7977 14.1317C14.7351 14.1576 14.6681 14.1709 14.6005 14.1709H5.41125C5.27459 14.1709 5.14354 14.1166 5.04691 14.02C4.95028 13.9234 4.896 13.7923 4.896 13.6556V11.1661"
        stroke={color}
        stroke-width="0.944752"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M11.5088 0.945312V4.55225H15.1157"
        stroke={color}
        stroke-width="0.944752"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M11.7284 5.66992H0.553333C0.406759 5.6705 0.266355 5.72889 0.162711 5.83236C0.0590672 5.93583 0.000582804 6.07599 0 6.22232V11.7308C0 11.8773 0.0582974 12.0178 0.162067 12.1214C0.265837 12.225 0.40658 12.2832 0.553333 12.2832H11.7284C11.8752 12.2832 12.0159 12.225 12.1197 12.1214C12.2235 12.0178 12.2818 11.8773 12.2818 11.7308V6.22232C12.2812 6.07599 12.2227 5.93583 12.1191 5.83236C12.0154 5.72889 11.875 5.6705 11.7284 5.66992Z"
        fill={color}
      />
      <path
        d="M1.17732 6.91928H2.00213C2.55201 6.91928 2.92382 6.9405 3.11757 6.98294C3.31316 7.02538 3.47185 7.13425 3.59363 7.30954C3.71726 7.48299 3.77908 7.7607 3.77908 8.14266C3.77908 8.4914 3.73572 8.72575 3.64899 8.84569C3.56227 8.96563 3.39158 9.03759 3.13694 9.06158C3.3676 9.11878 3.52259 9.19536 3.60194 9.29131C3.68128 9.38726 3.73018 9.47583 3.74863 9.55702C3.76893 9.63636 3.77908 9.85687 3.77908 10.2185V11.4004H2.69686V9.9113C2.69686 9.67142 2.67748 9.52288 2.63873 9.46568C2.60183 9.40848 2.50311 9.37988 2.34258 9.37988V11.4004H1.17732V6.91928ZM2.34258 7.68597V8.68238C2.47359 8.68238 2.56493 8.66486 2.61659 8.6298C2.6701 8.59289 2.69686 8.47572 2.69686 8.27828V8.03195C2.69686 7.88986 2.67103 7.79668 2.61936 7.75239C2.56954 7.70811 2.47728 7.68597 2.34258 7.68597ZM6.24521 6.91928L6.91226 11.4004H5.71933L5.6612 10.595H5.24326L5.17406 11.4004H3.96729L4.55961 6.91928H6.24521ZM5.62799 9.80059C5.56894 9.29315 5.50989 8.6667 5.45085 7.92123C5.33275 8.77741 5.25894 9.40386 5.22942 9.80059H5.62799ZM11.4958 6.91928L10.9782 11.4004H9.52232C9.38946 10.7121 9.27229 9.92975 9.17081 9.05327C9.12468 9.42785 9.01673 10.2102 8.84697 11.4004H7.3994L6.87905 6.91928H8.01109L8.1301 8.4831L8.25189 9.99157C8.29433 9.21104 8.40135 8.18694 8.57296 6.91928H9.78526C9.80187 7.05029 9.84431 7.54296 9.91258 8.3973L10.0399 10.0995C10.1045 9.01637 10.2124 7.95629 10.3637 6.91928H11.4958Z"
        fill="white"
      />
    </svg>
  );

  return (
    <div className="PopSideTabs">
      <div className={classNames('ai-popside-tab')} style={{ marginRight: 8 }} onClick={() => setTabActive('general')}>
        <div className={classNames('icon', { active: tabActive === 'general' })}>
          {iconGeneral(tabActive === 'general' ? '#3455DD' : '#6D6D7A')}
        </div>
        <Text textSize={14} textColor={tabActive === 'details' ? '#3455DD' : '#6D6D7A'} weight={500}>
          General
        </Text>
        {/* <div style={{ height: 1, width: '100%', backgroundColor: '#3455DD' }}></div> */}
      </div>
      <div className={classNames('ai-popside-tab')} style={{ margin: '0 8px' }} onClick={() => setTabActive('raw')}>
        <div className={classNames('icon', { active: tabActive === 'raw' })}>
          {iconRaw(tabActive === 'raw' ? '#3455DD' : '#6D6D7A')}
        </div>
        <Text textSize={14} textColor={tabActive === 'cves' ? '#3455DD' : '#6D6D7A'} weight={500}>
          Raw Source Details
        </Text>
      </div>
    </div>
  );
};

export default CNAPPAlertTabs;
