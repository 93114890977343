// @ts-nocheck
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { addHeaders } from 'common/services/rest-api.service';
import { env } from 'env';

export const runPromptCallback = createAsyncThunk('prompt/runPromptCallback', async (_, thunkAPI) => {
  const state = thunkAPI.getState();

  const res = await fetch(`${env.REACT_APP_BASE_URL}${state.prompt.callbackEndpoint}`, {
    method: 'POST',
    mode: 'cors',
    headers: addHeaders('POST'),
    credentials: 'include',
    body: JSON.stringify(state.prompt.callbackData)
  });
  if (!res.ok) {
    return rejectWithValue({ error: res });
  }
  return res;
});

const promptSlice = createSlice({
  name: 'prompt',
  initialState: {
    title: '',
    text: '',
    ctaText: '',
    callbackData: {},
    callbackEndpoint: '',
    visible: false
  },
  reducers: {
    setContent(state, action: any) {
      state.title = action.payload.title;
      state.text = action.payload.text;
      state.ctaText = action.payload.ctaText;
      state.callbackData = action.payload.callbackData;
      state.callbackEndpoint = action.payload.callbackEndpoint;
      state.status = '';
      state.successText = action.payload.successText;
      state.successTitle = action.payload.successTitle;
      state.status = action.payload.status;
      state.visible = true;
    },
    hide(state) {
      state.title = '';
      state.text = '';
      state.ctaText = '';
      state.callbackEndpoint = '';
      state.callbackData = {};
      state.status = '';
      state.successTitle = '';
      state.successText = '';
      state.visible = false;
    }
  },
  extraReducers: {
    [runPromptCallback.fulfilled]: (state, { payload }) => {
      state.status = 'success';
    },
    [runPromptCallback.rejected]: (state, { payload }) => {
      state.status = 'rejected';
    },
    [runPromptCallback.pending]: state => {
      state.status = 'loading';
    }
  }
});

const { actions, reducer } = promptSlice;
export const { setContent, hide } = actions;
export default reducer;
