/* eslint-disable */
import { Observable } from 'rxjs';
import { IObject, IResponseFetch, IResponsePaginatedData, IRootResponse } from '../interfaces/interface';
import { pathManageService } from 'login/services/pathManage.service';
import { env } from 'env';
import { store } from '../../app/store';
import { Common } from './common.service';

type MethodType = 'POST' | 'PUT' | 'PATCH' | 'DELETE';

//@description: Add a headers and check if there is csrftoken
export function addHeaders(method?: string) {
  let headers: IObject = {
    'Content-Type': 'application/json'
  };

  const account = store.getState().login.account || Common.getParamFromUrl('account');
  // const user: any = store.getState().login.user;

  if (account) {
    headers['X-Account-Name'] = account;
  }

  if (method === 'GET') {
    return headers;
  }

  if (document.cookie != null) {
    headers['X-CSRFTOKEN'] = getCSRFToken();
  }

  return headers;
}

export const RestApi = {
  //@param: path = Path to receive data
  //@return: data
  getPaginatedData: (path: string): Observable<IResponsePaginatedData> => {
    const url = `${env.REACT_APP_BASE_URL}${path}`;
    const method = 'GET';
    return new Observable(observer => {
      if (path) {
        fetch(url, {
          method: method,
          mode: 'cors',
          headers: addHeaders(method),
          credentials: 'include'
        })
          .then(response => {
            return responseManage(response);
          })
          .then(data => observer.next(data))
          .catch(error => {
            error.text().then((text: any) => {
              return observer.error(statusManage(error, text));
            });
          });
      } else {
        observer.error(false);
      }
    });
  },
  //@param: path = Path to receive data
  //@return: data
  //TODO I put "any" because that's the solution I found. I'll check tonight I do not want to dwell on it
  getData: (path: string, avoidRedirect?: boolean, signal?: AbortSignal): Observable<any> => {
    const url = `${env.REACT_APP_BASE_URL}${path}`;
    const method = 'GET';
    return new Observable(observer => {
      if (path) {
        fetch(url, {
          method: method,
          mode: 'cors',
          headers: addHeaders(method),
          credentials: 'include',
          signal
        })
          .then(response => {
            return responseManage(response);
          })
          .then(data => observer.next(data))
          .catch(error => {
            if (error.text) {
              error.text().then((text: any) => {
                if (avoidRedirect) {
                  return observer.error(error);
                } else {
                  return observer.error(statusManage(error, text));
                }
              });
            }
          });
      } else {
        observer.error(false);
      }
    });
  },
  async simpleAsyncGet(path: string) {
    const response = await fetch(`${env.REACT_APP_BASE_URL}${path}`, {
      method: 'GET',
      mode: 'cors',
      headers: addHeaders('GET'),
      credentials: 'include'
    });
    const json = await response.json();
    return json;
  },
  //@param: paths = Paths to receive data
  //@return: all data
  getDataPage(paths: any, avoidRedirect?: boolean, signal?: AbortSignal): Observable<IRootResponse> {
    let allData: any = {};
    let keysPath = Object.keys(paths);
    let count = keysPath.length;
    return new Observable(observer => {
      keysPath.forEach(key => {
        let url: string = paths[key];
        //TODO I put "any" because that's the solution I found. I'll check tonight I do not want to dwell on it
        this.getData(url, avoidRedirect, signal).subscribe(
          (response: any) => {
            allData[key] = response;
            count--;
            if (count === 0) {
              observer.next(allData);
            }
          },
          error => {
            count--;
            if (count === 0) {
              observer.error(error);
            }
          }
        );
      });
    });
  },

  getPromises(urls: string[], signal?: AbortSignal) {
    const promises = urls.map((url: string) => {
      try {
        return fetch(`${env.REACT_APP_BASE_URL}${url}`, {
          method: 'GET',
          mode: 'cors',
          headers: addHeaders(),
          credentials: 'include',
          signal
        });
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    });

    return promises;
  },

  getPromisesMixedMethods(urls: string[]) {
    const promises = urls.map((item: any) => {
      return fetch(`${env.REACT_APP_BASE_URL}${item.url}`, {
        method: item.method,
        mode: 'cors',
        headers: addHeaders(),
        credentials: 'include',
        ...(!!item.data ? { body: JSON.stringify(item.data) } : {})
      });
    });

    return promises;
  },

  async getPromiseAll(urlsData: any, signal?: AbortSignal) {
    try {
      const keys = Object.keys(urlsData);
      const promises = this.getPromises(Object.values(urlsData), signal);
      const responses = await Promise.all(promises);
      let index = 0;
      let result = {};
      for (let response of responses) {
        const data = !!response ? await response.json() : {};

        result = { ...result, [keys[index]]: data };
        index++;
      }
      return result;
    } catch (err) {
      // setIsFetchedFailure(true);
      return { err, isError: true };
    }
  },

  async getPromiseAllMixedMethods(urlsData: any) {
    try {
      const promises = this.getPromisesMixedMethods(Object.values(urlsData));
      const responses = await Promise.all(promises);
      let index = 0;
      let result: any = [];
      for (let response of responses) {
        if (!!urlsData[index].data) {
          const data = await response.json();
          result = [...result, { data, isError: !response.ok }];
        } else {
          result = [...result, { isError: !response.ok }];
        }
        index++;
      }
      return result;
    } catch (err) {
      // setIsFetchedFailure(true);
      return { err, isError: true };
    }
  },

  //@param: paths = Paths to receive data
  //@return: all data
  getDataPageByFlowPaths(paths: any, globalFilter: any): Observable<IRootResponse> {
    let allData: any = {};
    let keysPath = Object.keys(paths);
    return new Observable(observer => {
      keysPath.forEach(key => {
        let url: string = paths[key];
        //TODO I put "any" because that's the solution I found. I'll check tonight I do not want to dwell on it
        this.getData(`${url}${globalFilter ? `?group=${globalFilter}` : ''}`).subscribe(
          (response: any) => {
            allData[key] = response;
            observer.next(allData);
          },
          error => {
            observer.error(error);
          }
        );
      });
    });
  },
  //@param: path = Path to receive data
  //@param: data = Transfer data
  //@return: data
  setData(path: string, data: any = [], method: MethodType, avoidRedirect?: boolean) {
    let url = `${env.REACT_APP_BASE_URL}${path}`;
    return new Observable(observer => {
      fetch(url, {
        method: method,
        mode: 'cors',
        headers: addHeaders(),
        credentials: 'include',
        body: JSON.stringify(data)
      })
        .then(response => {
          return responseManage(response);
        })
        .then(data => observer.next(data))
        .catch(error => {
          error.text().then((text: any) => {
            if (!avoidRedirect) {
              if (!url.includes('/auth/')) {
                return observer.error(statusManage(error, text));
              } else {
                return observer.error(errorMessage(error, text));
              }
            } else {
              error.message = text;
              return observer.error(error);
            }
          });
        });
    });
  },
  logout(path: string) {
    const url = `${env.REACT_APP_BASE_URL}${path}`;
    fetch(url, {
      mode: 'cors',
      headers: addHeaders(),
      credentials: 'include'
    }).finally(() => {
      // This will reload the page on any response came back from the logout request,
      // if log out was succesfull, the reload will redirect to the login page,
      // if there was an error, same page will reload.
      // #TODO: we should have an error state which needs to reflect in the UI in case
      // log out was failed.
      window.location.reload();
    });
  }
};

function getCSRFToken() {
  /**
   * Replace with `csurf` package
   * TODO: https://www.stackhawk.com/blog/react-csrf-protection-guide-examples-and-how-to-enable-it/
   */
  let xsrfCookies = document.cookie
    .split(';')
    .map(c => c.trim())
    .filter(c => c.startsWith('csrftoken='));

  if (xsrfCookies.length === 0) {
    return '';
  }

  if (xsrfCookies.length > 1) {
    return xsrfCookies[xsrfCookies.length - 1].split('=')[1];
  }

  return xsrfCookies[0].split('=')[1];
}

function responseManage(response: IResponseFetch) {
  if (!response.ok) {
    throw response;
  }
  if (response.status === 204 || response.status === 202) {
    return response;
  }
  return response.json();
}

//@param: response of fetch
//@description: Status Manage
function statusManage(response: IResponseFetch, message: string = '') {
  switch (response.status) {
    case 401:
      pathManageService.checkPathHistory();
      return errorMessage(response, message);
    case 403:
      pathManageService.checkPathHistory();
      return errorMessage(response, message);
    default:
      return errorMessage(response, message);
  }
}

//@description: Error Message
function errorMessage(response: IResponseFetch, message: string = '') {
  let err: any = new Error(`${response.statusText}`);
  err.status = response.status;
  err.message = message;
  return err;
}
