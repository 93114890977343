import React from 'react';
export class IconStatuses extends React.Component {
  render() {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
        <path
          d="M1.125 6H3.375L4.5 1.5L7.5 10.5L8.625 6H10.875"
          stroke="#5D6576"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    );
  }
}
