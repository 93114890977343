import { IPaths } from '../interfaces/interface';
import { PathNames } from './pathNames';

//List of portal paths
export const Paths: IPaths = {};
/**
 * Inventory
 */
Paths[PathNames.inventoryMetaData] = 'discovery/metadata/';

Paths[PathNames.internalAssets] = 'discovery/org-assets/';
Paths[PathNames.internalAssetsColumns] = 'discovery/org-assets/columns/';
Paths[PathNames.internalAssetsSummary] = 'discovery/org-assets/summary/';

Paths[PathNames.digitalSupplyChain] = 'discovery/digital-supply-chain/';
Paths[PathNames.digitalSupplyChainColumns] = 'discovery/digital-supply-chain/columns/';
Paths[PathNames.digitalSupplyChainSummary] = 'discovery/digital-supply-chain/summary/';

Paths[PathNames.loginAssets] = 'discovery/logins/';
Paths[PathNames.loginAssetsColumns] = 'discovery/logins/columns/';
Paths[PathNames.loginAssetsSummary] = 'discovery/logins/summary/';

Paths[PathNames.unlinkedAssets] = 'discovery/unlinked-assets/';
Paths[PathNames.unlinkedAssetsColumns] = 'discovery/unlinked-assets/columns/';

Paths[PathNames.certificates] = 'discovery/certificates/';
Paths[PathNames.certificatesColumns] = 'discovery/certificates/columns/';

Paths[PathNames.managedDomains] = 'discovery/managed-domains/';
Paths[PathNames.managedDomainsColumns] = 'discovery/managed-domains/columns/';

Paths[PathNames.publicCloud] = 'discovery/cloud/';
Paths[PathNames.publicCloudColumns] = 'discovery/cloud/columns/';

Paths[PathNames.mobileAssets] = 'discovery/mobile/';
Paths[PathNames.mobileAssetsColumns] = 'discovery/mobile/columns/';
/**
 * Assesments
 */
Paths[PathNames.assesments] = 'assessments/';
Paths[PathNames.assesmentsInternalAssets] = 'assessments/org-assets/';
Paths[PathNames.assesmentsDigitalSupplyChain] = 'assessments/digital-supply-chain/';
Paths[PathNames.assesmentsInternalAssetsColumns] = 'assessments/org-assets/columns/';
Paths[PathNames.assesmentsDigitalSupplyChainColumns] = 'assessments/digital-supply-chain/columns/';

Paths[PathNames.assesmentsDeepDarkMonitoring] = 'assessments/drps/darkweb/alerts/';
Paths[PathNames.assesmentsLeakedCredentials] = 'assessments/drps/compromised-devices/';
Paths[PathNames.assesmentsDeepDarkMonitoringColumns] = 'assessments/drps/darkweb/alerts/columns/';
Paths[PathNames.assesmentsLeakedCredentialsColumns] = 'assessments/drps/compromised-devices/columns/';

Paths[PathNames.assesmentsMetaData] = 'assessments/metadata/';
Paths[PathNames.assesmentsOrgScoreSummary] = 'assessments/org_score/summary/';
Paths[PathNames.assesmentsOrgScoreCards] = 'assessments/org_score/';
Paths[PathNames.assesmentsOrgScoreSubsidiaries] = 'assessments/org_score/summary/subsidiaries/';
Paths[PathNames.assesmentsOrgScoreColumns] = 'assessments/org_score/:category_id/columns/';
Paths[PathNames.assesmentsOrgScoreCategoryData] = 'assessments/org_score/:category_id/';
Paths[PathNames.assesmentsOrgScoreIssuesColumns] = 'assessments/org_score/:category_id/issues/columns/';
Paths[PathNames.assesmentsOrgScoreIssuesData] = 'assessments/org_score/:category_id/issues/';

Paths[PathNames.assesmentsVendorMonitoringColumns] = 'assessments/vendor_monitoring/columns/';
Paths[PathNames.assesmentsVendorMonitoring] = 'assessments/vendor_monitoring/';
/**
 * Active Protection
 */
Paths[PathNames.activeProtection] = 'remediation/active-protection/';
Paths[PathNames.activeProtectionColumns] = 'remediation/active-protection/columns/';
Paths[PathNames.activeProtectionSummary] = 'remediation/active-protection/summary/';

/**
 * Action Items
 */
Paths[PathNames.actionItemsMetaData] = 'remediation/action-items/metadata/';

Paths[PathNames.actionItemsOpen] = 'remediation/action-items/open/';
Paths[PathNames.actionItemsOpenColumns] = 'remediation/action-items/open/columns/';
Paths[PathNames.actionItemsOpenSummary] = 'remediation/action-items/open/summary/';

Paths[PathNames.actionItemsClose] = 'remediation/action-items/closed/';
Paths[PathNames.actionItemsCloseColumns] = 'remediation/action-items/closed/columns/';
Paths[PathNames.actionItemsCloseSummary] = 'remediation/action-items/closed/summary/';

Paths[PathNames.actionItemsCnappAlerts] = 'alerts/';
Paths[PathNames.actionItemsCnappAlertsColumns] = 'alerts/columns/';

/**
 * Users
 */
Paths[PathNames.users] = 'auth/users/?limit=4&offset=0';
Paths[PathNames.logout] = 'auth/logout/';

/**
 * Dashboard
 */
Paths[PathNames.action_items_chart] = 'dashboard/action_items_chart/';
Paths[PathNames.action_items_graph] = 'dashboard/action_items_graph/';
Paths[PathNames.action_items_pie] = 'dashboard/action_items_pie/';
Paths[PathNames.action_items_graph_open] = 'dashboard/action_items_graph_open/';
Paths[PathNames.action_items_graph_close] = 'dashboard/action_items_graph_close/';
Paths[PathNames.action_items_summary] = 'dashboard/action_items_summary/';
Paths[PathNames.connections_pie] = 'dashboard/connections_pie/';
Paths[PathNames.geomap] = 'dashboard/geomap/';
Paths[PathNames.hosts_pie] = 'dashboard/hosts_pie/';
Paths[PathNames.dashboard_summary] = 'dashboard/summary/';
Paths[PathNames.top_assets] = 'dashboard/top_assets/';

/**
 * Settings
 */
Paths[PathNames.scanInfo] = 'company/scan-info/';
Paths[PathNames.integrations] = 'settings/integrations/';
Paths[PathNames.userManagement] = 'settings/users/user-management/';
Paths[PathNames.notifications] = 'settings/notifications/';
Paths[PathNames.groups] = 'settings/groups/';
Paths[PathNames.metaGroups] = 'settings/meta-groups/';
Paths[PathNames.subsidiaries] = 'settings/organization-management/subsidiaries/';
Paths[PathNames.domainRestriction] = 'settings/permissions/domain-access-restrictions/';
Paths[PathNames.ipRestriction] = 'settings/permissions/ip-restrictions/';
Paths[PathNames.manageFqdns] = 'settings/organization-management/manage-fqdns/';
Paths[PathNames.ipRanges] = 'settings/organization-management/ip-ranges/';
Paths[PathNames.auditLogs] = 'settings/general/audit-logs/';
Paths[PathNames.tokens] = 'settings/tokens/';
Paths[PathNames.customPort] = 'settings/general/custom-ports/';
Paths[PathNames.seedData] = 'settings/seed-data/';
Paths[PathNames.services] = 'settings/groups/services/';

Paths[PathNames.integrationJira] = 'settings/integrations/jira/';
Paths[PathNames.integrationSplunk] = 'settings/integrations/splunk/';
Paths[PathNames.integrationSentinel] = 'settings/integrations/sentinel/';
Paths[PathNames.integrationServiceNow] = 'settings/integrations/servicenow/';
