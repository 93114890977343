import { useEffect, useRef, useState } from 'react';
import { Button } from '../Button/Button';
import { Input } from '../Input/Input';
import { Search } from '../Search/Search';
import classNames from 'classnames';
import { RestApi } from 'common/services/rest-api.service';
import { AppLoading } from '../appLoading/appLoading';
import { queryBuilder } from '../grid/query-builder.service';
import { updateRows } from 'features/GridSlice';
import { useDispatch, useSelector } from 'react-redux';
import { IReduxState } from 'app/store';
import './AddRemoveTags.scss';

interface ITagsDropDownProps {
  method: string;
  tags: any;
  ids: number[];
  onClose: () => void;
  apiPath: string;
  identifier: string;
  loadedRows: any[];
  onSubmitted?: (tags: string[]) => void;
}

export function AddRemoveTags(props: ITagsDropDownProps) {
  const [allTags, setAllTags] = useState<string[]>([]);
  const [newTagInput, setNewTagInput] = useState<string>('');
  const [search, setSearch] = useState<string>('');
  const [tagsToAdd, setTagsToAdd] = useState<string[]>([]);
  const [tagsToRemove, setTagsToRemove] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [submitted, setSubmitted] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const { rows } = useSelector((state: IReduxState) => state.grid);
  const dispatch = useDispatch<any>();
  const ref: any = useRef();

  useEffect(() => {
    if (props.method === 'remove') {
      const selectedRowsTags: any = new Set();
      props.loadedRows.forEach((row: any) => {
        if (!props.ids.length) {
          row.tags?.forEach((tag: any) => {
            if (tag.source === 'Custom') {
              selectedRowsTags.add(tag.name);
            }
          });
        }
        if (props.ids.includes(row[props.identifier])) {
          row.tags?.forEach((tag: any) => {
            if (tag.source === 'Custom') {
              selectedRowsTags.add(tag.name);
            }
          });
        }
      });
      setAllTags([...selectedRowsTags]);
      setLoading(false);
    } else {
      setAllTags(
        props.tags.choices
          .filter((tag: any) => tag.source !== 'IONIX')
          .map((_tag: any) => {
            return _tag.name;
          })
      );
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (props.ids.length > 100) {
      setError('Please manually select up to 100 individual rows or use filters to remove tags in bulk');
    }
  }, [props.ids]);

  const onSelectTag = (tag: string) => {
    if (submitting) {
      return;
    }
    if (props.method === 'remove') {
      const exists = tagsToRemove.indexOf(tag);
      if (exists > -1) {
        const copy = [...tagsToRemove];
        copy.splice(exists, 1);
        setTagsToRemove(copy);
      } else {
        setTagsToRemove([...tagsToRemove, tag]);
      }
    } else {
      const exists = tagsToAdd.indexOf(tag);
      if (exists > -1) {
        const copy = [...tagsToAdd];
        copy.splice(exists, 1);
        setTagsToAdd(copy);
      } else {
        setTagsToAdd([...tagsToAdd, tag]);
      }
    }
  };

  const getListHTML = (): JSX.Element[] => {
    const html: JSX.Element[] = [];
    allTags.forEach((_tag: string) => {
      if (!search || (search && _tag.toLowerCase().includes(search.toLowerCase()))) {
        html.push(
          <li>
            <span
              className={classNames({ new: tagsToAdd.includes(_tag), remove: tagsToRemove.includes(_tag) })}
              onClick={() => onSelectTag(_tag)}
            >
              {_tag}
            </span>
          </li>
        );
      }
    });
    return html;
  };

  const onAddTag = () => {
    if (!allTags.includes(newTagInput) && newTagInput.length > 1) {
      setTagsToAdd([...tagsToAdd, newTagInput]);
      setAllTags([newTagInput, ...allTags]);
      setNewTagInput('');
    }
  };

  const handleClickOutside = (event: Event) => {
    if (submitting || loading) {
      return;
    }
    if (ref.current && !ref.current.contains(event.target as Node)) {
      props.onClose();
    }
  };

  const handleAddTags = () => {
    if (!props.ids.length) {
      const updatedRows = rows?.reduce((all: any, row: any) => {
        const existingTags = row.tags?.map((tag: any) => tag.name) || [];
        return [
          ...all,
          {
            ...row,
            tags: [
              ...new Set([
                ...(!!row.tags ? row.tags : []),
                ...tagsToAdd
                  .filter((tag: any) => !existingTags.includes(tag))
                  .map((tag: any) => ({ name: tag, source: 'Custom' }))
              ])
            ]
          }
        ];
      }, []);

      dispatch(updateRows({ rows: updatedRows, identifier: 'id' }));
    } else {
      const updatedRows = props.ids?.reduce((all: any, id: any) => {
        const row = rows.find((row: any) => row.id === id);
        const existingTags = row.tags?.map((tag: any) => tag.name) || [];
        return [
          ...all,
          {
            ...row,
            tags: [
              ...new Set([
                ...(!!row.tags ? row.tags : []),
                ...tagsToAdd
                  .filter((tag: any) => !existingTags.includes(tag))
                  .map((tag: any) => ({ name: tag, source: 'Custom' }))
              ])
            ]
          }
        ];
      }, []);

      dispatch(updateRows({ rows: updatedRows, identifier: 'id' }));
    }
  };

  const handleRemoveTags = () => {
    if (!props.ids.length) {
      const updatedRows = rows?.reduce((all: any, row: any) => {
        return [
          ...all,
          {
            ...row,
            tags: [...(!!row.tags ? row.tags?.filter((tag: any) => !tagsToRemove.includes(tag.name)) : [])]
          }
        ];
      }, []);

      dispatch(updateRows({ rows: updatedRows, identifier: 'id' }));
    } else {
      const updatedRows = props.ids?.reduce((all: any, id: any) => {
        const row = rows.find((row: any) => row.id === id);
        return [
          ...all,
          {
            ...row,
            tags: [...(!!row.tags ? row.tags?.filter((tag: any) => !tagsToRemove.includes(tag.name)) : [])]
          }
        ];
      }, []);

      dispatch(updateRows({ rows: updatedRows, identifier: 'id' }));
    }
  };

  const submit = () => {
    setSubmitting(true);
    RestApi.setData(
      props.apiPath + 'tags/?' + queryBuilder.getQuery().split('?')[1],
      { ids: props.ids || [], tags: props.method === 'remove' ? tagsToRemove : tagsToAdd },
      props.method === 'remove' ? 'DELETE' : 'POST'
    ).subscribe(
      (response: any) => {
        if (props.method === 'add') {
          handleAddTags();
        } else if (props.method === 'remove') {
          const tagsAfterRemove = allTags.filter((tag: any) => !tagsToRemove.includes(tag));
          setAllTags(tagsAfterRemove);

          handleRemoveTags();
        }
        setNewTagInput('');
        setTagsToAdd([]);
        setTagsToRemove([]);
        setSubmitting(false);
        setSubmitted(true);
        if (props.onSubmitted) {
          props.onSubmitted(tagsToAdd);
        }
      },
      error => {
        setNewTagInput('');
        setTagsToAdd([]);
        setTagsToRemove([]);
        setSubmitting(false);
      }
    );
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  });

  return (
    <div className="AddRemoveTags" ref={ref}>
      {loading && <AppLoading />}
      {error ? (
        <div className="error">
          <img src="/assets/images/toster-warning.svg" alt="Warning" />
          <div>{error}</div>
        </div>
      ) : (
        ''
      )}
      <Search onChange={setSearch} placeholder={false} />

      {props.method === 'add' ? (
        <div className="create-new-wrapper">
          <Input
            type="text"
            maxlength={30}
            placeholder={'Create New Tag'}
            value={newTagInput}
            onChange={(input: string) => {
              setSubmitted(false);
              setNewTagInput(input);
            }}
            onKeyDown={(event: any) => {
              if (event.key === 'Enter') {
                onAddTag();
              }
            }}
          />
          <Button size="tiny" text="+" onClick={onAddTag} buttonStyle="secondary" type="button" />
        </div>
      ) : (
        ''
      )}
      <div className="scrollbar-common">
        {allTags.length === 0 && tagsToAdd.length === 0 ? (
          <div>No Available Tags</div>
        ) : (
          <ul style={{ height: '100%', overflow: 'auto' }}>{getListHTML()}</ul>
        )}
      </div>
      {props.method === 'add' ? (
        <div className="buttons-wrapper">
          <div>
            {!!submitting ? (
              <AppLoading />
            ) : submitted ? (
              <div className="success">
                <span></span> New tags saved successfully
              </div>
            ) : (
              'Uploading tags may take several minutes'
            )}
          </div>
          <Button
            type="button"
            text="Apply"
            onClick={submit}
            buttonStyle="main"
            size="small"
            disabled={!tagsToAdd.length || submitting}
          />
        </div>
      ) : (
        <div className="buttons-wrapper">
          <div>
            {!!submitting ? (
              <AppLoading />
            ) : submitted ? (
              'Tags removed successfully'
            ) : (
              'Removing tags may take several minutes'
            )}
          </div>
          <Button
            type="button"
            text="Remove"
            onClick={submit}
            buttonStyle="alert"
            size="small"
            disabled={!tagsToRemove.length || submitting}
          />
        </div>
      )}
    </div>
  );
}
