import React from 'react';
import { LinkNewTab } from 'common/components/LinkNewTab/LinkNewTab';
import { Text } from '@cyberpion/cyberpion-ui';
import { ToolTip } from 'common/components/toolTip/toolTip';
import { cwes } from './cwe';

const iconInfo = (
  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
    <path
      d="M10.5 6C10.5 8.48528 8.48528 10.5 6 10.5C3.51472 10.5 1.5 8.48528 1.5 6C1.5 3.51472 3.51472 1.5 6 1.5C8.48528 1.5 10.5 3.51472 10.5 6Z"
      stroke="#5D6576"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path d="M6 5.5L6 8.3" stroke="#5D6576" stroke-width="1.1" stroke-linecap="round" />
    <circle cx="5.9999" cy="3.8998" r="0.6" fill="#5D6576" />
  </svg>
);

const linkIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
    <path d="M7.5249 2.5H10.0749V5.05" stroke="#3659DE" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M10.075 2.5L6.25 6.325" stroke="#3659DE" stroke-linecap="round" stroke-linejoin="round" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5.8248 2H3.4248C2.59638 2 1.9248 2.67157 1.9248 3.5V9.15C1.9248 9.97843 2.59638 10.65 3.42481 10.65H9.0748C9.90323 10.65 10.5748 9.97843 10.5748 9.15V6.75H9.5748V9.15C9.5748 9.42614 9.35095 9.65 9.0748 9.65H3.42481C3.14866 9.65 2.9248 9.42614 2.9248 9.15V3.5C2.9248 3.22386 3.14866 3 3.4248 3H5.8248V2Z"
      fill="#3659DE"
    />
  </svg>
);

const CveItem = ({ cve }: { cve: any }) => {
  const getNistToolTipHtml = (cve: any) => {
    return `<div class="AI-Cvss-Tooltip">
      <div class='main-title'>CVSS v${cve.cvss_version || ''} Severity and Metrics</div>
      <div class='score-row'>
        <div class='score-row-item'>
          <div class='title'>Base Score</div>
          <div class='value'>${cve.base_severity?.toLowerCase() || ''}</div>
        </div>
        <div class='score-row-item'>
          <div class='title'>Impact Score</div>
          <div class='value'>${cve.impact_score || ''}</div>
        </div>
        <div class='score-row-item'>
          <div class='title'>Exploitability Score</div>
          <div class='value'>${cve.exploitability_score || ''}</div>
        </div>
      </div>
      <div class='row-item'>
        <div class='title'>Vector</div>
        <div class='value'>${cve.cvss_vector || ''}</div>
      </div>
      <div class="tt-main">
        <div class='col-item-wrapper'>
          <div class='col-item'>
            <div class='title'>Attack Vecor</div>
            <div class='value'>${cve.attack_vector?.toLowerCase() || ''}</div>
          </div>
          <div class='col-item'>
            <div class='title'>User Interaction</div>
            <div class='value'>${cve.user_interaction ? 'Required' : 'None'}</div>
          </div>
          <div class='col-item'>
            <div class='title'>Integrity</div>
            <div class='value'>${cve.integrity_impact?.toLowerCase() || ''}</div>
          </div>
        </div>

        <div class='col-item-wrapper'>
          <div class='col-item'>
            <div class='title'>Attack Complexity</div>
            <div class='value'>${cve.attack_complexity?.toLowerCase() || ''}</div>
          </div>
          <div class='col-item'>
            <div class='title'>Scope</div>
            <div class='value'>${cve.scope?.toLowerCase() || ''}</div>
          </div>
          <div class='col-item'>
            <div class='title'>Availability</div>
            <div class='value'>${cve.availability_impact?.toLowerCase() || ''}</div>
          </div>
        </div>

        <div class='col-item-wrapper'>
          <div class='col-item'>
            <div class='title'>Privileges Required</div>
            <div class='value'>${cve.privileges_required?.toLowerCase() || ''}</div>
          </div>
          <div class='col-item'>
            <div class='title'>Confidentiality</div>
            <div class='value'>${cve.confidentiality_impact?.toLowerCase() || ''}</div>
          </div>
          <div class='col-item'>
            <div class='title'></div>
            <div class='value'>${''}</div>
          </div>
        </div>
      </div>
    </div>`;
  };

  const getCWEToolTipHtml = (item: any, id: any) => {
    return `<div class='AI-Cwe-Tooltip'>
      <div class='cwe-main-title'>CWE-${id}</div>
      <div class='cwe-link'><a target='_blank' rel='nofollower' href="${item.link}">${item.name}</a></div>
      <div class='cwe-description'>${
        item.description && item.description.length > 500 ? item.description.slice(0, 500) + '...' : item.description
      }</div>
    </div>`;
  };

  const handleCVEItemOnClick = () => {
    window.open(`https://nvd.nist.gov/vuln/detail/${cve.cve}`, '_blank');
  };

  return (
    <div className="cve-item">
      <div className="cve-item-header">
        <div className="color-line" />
        <div className="cve-item-name-wrapper" onClick={handleCVEItemOnClick}>
          <Text textSize={13} textColor="#4a79e7">
            {cve.cve}
          </Text>
          <div style={{ marginLeft: 4 }}>{linkIcon}</div>
        </div>
        <span className="cve-item-details">
          CVSS: {cve.cvss}
          {!!cve.epss && !!cve.epss_percentile
            ? `, (EPSS Score: ${(cve?.epss * 100).toFixed(2)}%,
                Percentile: ${(cve.epss_percentile * 100).toFixed(2)}%)`
            : ''}
        </span>
        {cve.is_cisa && (
          <div className="item-exploited">
            <LinkNewTab
              to={'https://www.cisa.gov/known-exploited-vulnerabilities-catalog'}
              text={'CISA exploited'}
              className="cisa"
            />
          </div>
        )}
      </div>
      <div style={{ display: 'flex', alignItems: 'center', borderBottom: '1px solid #e8e8e8' }}>
        {!!cve.cvss_vector && (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div className="cve-item-body">NIST CVSS Vector: {cve.cvss_vector}</div>
            <div data-tip={getNistToolTipHtml(cve)} data-for={'CVETT'} style={{ position: 'relative', top: 1 }}>
              {iconInfo}
            </div>
          </div>
        )}
        {!!cve.cwe?.length && (
          <div className="cwe-item">
            {cve.cwe.map((_cwe: string | number, index: number) => {
              return (
                <div
                  style={{ display: 'flex', alignItems: 'center', padding: '0 12px', borderLeft: '1px solid #e8e8e8' }}
                >
                  <Text textSize={13} style={{ paddingRight: 8 }}>{`CWE-${_cwe}`}</Text>
                  <div
                    data-tip={cwes[_cwe] ? getCWEToolTipHtml(cwes[_cwe], _cwe) : ''}
                    data-for={'CVETT'}
                    style={{ position: 'relative', top: 1 }}
                  >
                    {iconInfo}
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </div>

      <div className="cve-item-description">
        <Text textSize={14} textColor="#5D6476">
          {cve.description}
        </Text>
      </div>
    </div>
  );
};

const PopSideCVEsTab = ({ data }: { data: any }) => {
  return (
    <div className="PopSideCvesTab">
      {data.cves &&
        data.cves.length > 0 &&
        data.cves.map((cve: any, index: number) => <CveItem key={index} cve={cve} />)}
      <ToolTip html={true} theme="light" className="stay" place="left" delayHide={300} id="CVETT" effect="solid" />
    </div>
  );
};

export default PopSideCVEsTab;
