import { useEffect, useState } from 'react';
import { IconArrow } from '../Icons/IconArrow/IconArrow';
import { AddRemoveTags } from '../AddRemoveTags/AddRemoveTags';
import { useDispatch, useSelector } from 'react-redux';
import { IReduxState } from 'app/store';
import classNames from 'classnames';
import { useComponentVisible } from 'common/hooks/useComponentVisible';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { queryBuilder } from '../grid/query-builder.service';
import { Common } from 'common/services/common.service';
import { SCAN_STATUS } from '../ScanStatus/ScanStatus';
import { RestApi } from 'common/services/rest-api.service';
import { addDownloadFileData, setNewDownloads } from 'features/DownloadsSlice';
import Tooltip from '@mui/material/Tooltip';
import { Text } from '@cyberpion/cyberpion-ui';
import { showSuccess } from 'features/AlertSlice';
import { setDropDownWithAction, setShowAddCommentPopup, updateRows } from 'features/GridSlice';
import './select.scss';

const iconArrow = (
  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
    <path
      d="M3.66667 9.33366L7 6.00033L3.66667 2.66699"
      stroke="#5D6576"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

const iconInfo = (
  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
    <path
      d="M10.5 6C10.5 8.48528 8.48528 10.5 6 10.5C3.51472 10.5 1.5 8.48528 1.5 6C1.5 3.51472 3.51472 1.5 6 1.5C8.48528 1.5 10.5 3.51472 10.5 6Z"
      stroke="#5D6576"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path d="M6 5.5L6 8.3" stroke="#5D6576" stroke-width="1.1" stroke-linecap="round" />
    <circle cx="5.9999" cy="3.8998" r="0.6" fill="#5D6576" />
  </svg>
);

interface IOption {
  IconComponent?: any;
  data?: any;
  label: string;
  ff?: string;
  subMenuType?: string;
}

interface ISelect {
  options: IOption[];
  header: string;
  apiPath: string;
  callbackChange: (label: IOption) => void;
  ids: number[];
  identifier: string;
  loadedRows: any[];
  selectedRows?: any[];
}

export function Select(props: ISelect) {
  const [showTagsDropDown, setShowTagsDropDown] = useState<boolean>(false);
  const [tagsMethod, setTagsMethod] = useState<string>('');
  const [subMenuOptions, setSubMenuOptions] = useState<any>(null);
  const { columnPicker } = useSelector((state: IReduxState) => state.columnPicker);
  const { rows } = useSelector((state: IReduxState) => state.grid);
  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);
  const flags: any = useFlags();
  const dispatch = useDispatch<any>();

  useEffect(() => {
    if (!isComponentVisible) {
      setSubMenuOptions(null);
    }
  }, [isComponentVisible]);

  function handleChange() {
    if (isComponentVisible) {
      setShowTagsDropDown(false);
    }
    setIsComponentVisible(!isComponentVisible);
  }

  async function onChange(item: IOption) {
    const isMsspAccount = Common.getParamFromUrl('account');
    if (item.label === 'Add Tags') {
      setTagsMethod('add');
      setShowTagsDropDown(true);
    } else if (item.label === 'Remove Tags') {
      setTagsMethod('remove');
      setShowTagsDropDown(true);
    } else if (item.label === 'Update Status') {
      const subOptions = columnPicker.find((col: any) => col.name === 'current_state').choices;
      setSubMenuOptions(subOptions);
    } else if (item.label === 'Export Discovery Evidence') {
      let csv;
      if (props.ids.length) {
        csv = Common.AddMsspAccountIfNeeded(
          `discovery/org-assets/export-discovery-evidence/?asset__in=${props.ids.join(',')}&format=csv`
        );
      } else {
        csv = `discovery/org-assets/export-discovery-evidence/?${queryBuilder.getQuery().split('?')[1]}&format=csv${
          isMsspAccount ? `&account=${isMsspAccount}` : ''
        }`;
      }
      const response = await RestApi.simpleAsyncGet(csv);
      dispatch(addDownloadFileData(response));
      dispatch(setNewDownloads(response?.id));
    } else {
      setIsComponentVisible(false);
      props.callbackChange(item);
    }
  }

  async function subMenuOnChange(item: any) {
    const url = Common.AddMsspAccountIfNeeded('remediation/action-items/state/');

    if (item.name !== 'security_patch_applied') {
      const payload = {
        ids: props.selectedRows,
        state: item.name
      };
      RestApi.setData(url, payload, 'POST').subscribe((response: any) => {
        dispatch(updateRows({ rows: response, identifier: 'id' }));
        dispatch(showSuccess('Status Updated Successfully'));
      });
    } else {
      const statusOptions = columnPicker.find((col: any) => col.type === 'dropdown_with_action').choices;
      const nextOption = statusOptions.find((opt: any) => opt.label === 'Security Patch Applied');

      const listOptions = props.selectedRows?.reduce((all: any, id: any) => {
        const rowState = rows.find((row: any) => row.id === id).current_state;
        const option = statusOptions.find((opt: any) => opt.label === rowState);
        return {
          ...all,
          [id]: option
        };
      }, {});
      dispatch(
        setDropDownWithAction({
          listLastStatusOption: listOptions,
          nextStatusOption: nextOption
        })
      );

      const updatedRows = props.selectedRows?.reduce((all: any, id: any) => {
        const row = rows.find((row: any) => row.id === id);
        return [
          ...all,
          {
            ...row,
            current_state: 'Security Patch Applied'
          }
        ];
      }, []);
      dispatch(updateRows({ rows: updatedRows, identifier: 'id' }));
      dispatch(setShowAddCommentPopup(true));
    }
    setIsComponentVisible(false);
  }

  const isDisabled = (item: any) => {
    if (item.label === 'Rescan') {
      if (
        props.loadedRows.filter(
          (row: any) => row.rescan_status === SCAN_STATUS.IN_PROGRESS && props.selectedRows?.includes(row.id)
        ).length
      ) {
        return true;
      }
      if (!props.selectedRows?.length) {
        return true;
      }
      return false;
    }
    if (['Acknowledge', 'Unacknowledge'].includes(item.label) && !props.selectedRows?.length) {
      return true;
    }
    if (item.label === 'Update Status') {
      if (!props.selectedRows?.length) {
        return true;
      }
    }
    return false;
  };

  const isSubMenuDisabled = (item: any) => {
    if (item.label === 'Security Patch Applied') {
      const disabled = props.loadedRows.some(
        (row: any) => props.selectedRows?.includes(row.id) && row.type !== 'Vulnerable Component'
      );
      return disabled;
    }
    return false;
  };

  const handleBackOnClick = () => {
    setSubMenuOptions(null);
  };

  return (
    <div className="select-wrapper" ref={ref}>
      <div className="select-header-wrapper" onClick={handleChange} data-testid={props.header}>
        <div className="select-header">{props.header}</div>
        <IconArrow active={isComponentVisible} toggleRotate={isComponentVisible} />
      </div>
      {showTagsDropDown && (
        <AddRemoveTags
          method={tagsMethod}
          tags={columnPicker.filter((c: any) => c.name === 'tags')[0]}
          ids={props.ids || []}
          onClose={() => setShowTagsDropDown(false)}
          apiPath={props.apiPath}
          identifier={props.identifier}
          loadedRows={props.loadedRows}
        />
      )}
      {isComponentVisible && !subMenuOptions?.length && (
        <div className="select-body-wrapper">
          {props.options
            .filter((option: IOption) => (option.ff && flags[option.ff]) || !option.ff)
            .map((item: IOption, key: number) => {
              return (
                <div className={classNames('select-item-scope', { disabled: isDisabled(item) })} key={key}>
                  {item.IconComponent && <item.IconComponent />}
                  <div className={classNames('select-label')} onClick={() => onChange(item)}>
                    {item.label}
                  </div>
                  {!!item.subMenuType && !isDisabled(item) && (
                    <div className="submenu-icon" onClick={() => onChange(item)}>
                      {iconArrow}
                    </div>
                  )}
                </div>
              );
            })}
        </div>
      )}
      {isComponentVisible && !!subMenuOptions?.length && (
        <div className="select-body-wrapper">
          <div className="sub-menu-title" onClick={handleBackOnClick}>
            <div className="submenu-icon">{iconArrow}</div>
            <Text weight={500}>Update Status</Text>
          </div>
          <div>
            {subMenuOptions.map((item: IOption, key: number) => {
              return (
                <div className="select-item-scope">
                  <div className={classNames({ disabled: isSubMenuDisabled(item) })} key={key}>
                    <div className={classNames('select-label')} onClick={() => subMenuOnChange(item)}>
                      {item.label}
                    </div>
                  </div>
                  {isSubMenuDisabled(item) && (
                    <Tooltip
                      title={<Text textColor="#FFF">Allowed only for Vulnerable Component type</Text>}
                      placement="bottom-start"
                      componentsProps={{
                        tooltip: {
                          sx: {
                            bgcolor: '#4D4C53',
                            color: '#FFFFFF',
                            padding: '3px 6px',
                            marginBottom: '0px !important',
                            '& .MuiTooltip-arrow': {
                              color: '#4D4C53'
                            },
                            border: '1px solid black',
                            borderRadius: 1
                          }
                        }
                      }}
                    >
                      <div className="tooltip-icon">{iconInfo}</div>
                    </Tooltip>
                  )}
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
}
