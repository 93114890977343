import classNames from 'classnames';
import './PendingScan.scss';
import { useRef, useState } from 'react';
import { useClickOutside } from 'hooks/useClickOutside';
import { Button } from '@cyberpion/cyberpion-ui';
import { addHeaders } from 'common/services/rest-api.service';
import { env } from 'env';

const PendingScan = (props: any) => {
  const [visible, setVisible] = useState<boolean>(false);
  const [submit, setSubmit] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);
  const ref = useRef(null);
  useClickOutside(ref, () => {
    setVisible(false);
  });

  const submitRequest = async () => {
    setSubmit(true);
    try {
      await fetch(`${env.REACT_APP_BASE_URL}company/plan-upgrade-request/`, {
        method: 'POST',
        mode: 'cors',
        headers: addHeaders('POST'),
        credentials: 'include'
      });
      setSuccess(true);
    } catch (err) {
      setSubmit(false);
    }
  };

  return (
    <div className="PendingScan" ref={ref}>
      <div className="image-wrapper">
        <img src="/assets/images/time.svg" alt="Pending" />
      </div>
      <div style={{ marginLeft: 12, fontSize: 13, color: '#6D6D7A' }}>Pending Scan Cycle</div>
      <img className="info" src="/assets/images/info.svg" alt="Info" onClick={() => setVisible(!visible)} />
      <div className={classNames('tooltip', { visible: visible })}>
        <h6>Pending Scan Cycle</h6>
        <p>Real-time Threat Center exploit validation is not included in your plan, Please contact us to upgrade</p>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Button
            disabled={submit}
            text="Request Upgrade"
            size="medium"
            type="button"
            buttonStyle="main"
            onClick={submitRequest}
          />
          {success && <img style={{ marginLeft: 10 }} src="/assets/images/ok-green.svg" alt="OK" />}
        </div>
      </div>
    </div>
  );
};

export default PendingScan;
